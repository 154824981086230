import {
  PressResponderContext
} from "./chunk-4XDBEIOV.mjs";
import {
  PressEvent
} from "./chunk-7OGZUKMH.mjs";
import {
  disableTextSelection,
  restoreTextSelection
} from "./chunk-CWMPADA3.mjs";
import {
  getOwnerDocument,
  getOwnerWindow
} from "./chunk-FFBWCXWK.mjs";
import "./chunk-7D66DBGQ.mjs";

// src/index.ts
import {
  focusWithoutScrolling,
  isMac,
  isVirtualClick,
  isVirtualPointerEvent,
  mergeProps,
  openLink,
  useEffectEvent,
  useGlobalListeners,
  useSyncRef
} from "@react-aria/utils";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
function usePressResponderContext(props) {
  let context = useContext(PressResponderContext);
  if (context) {
    let { register, ...contextProps } = context;
    props = mergeProps(contextProps, props);
    register();
  }
  useSyncRef(context, props.ref);
  return props;
}
var LINK_CLICKED = Symbol("linkClicked");
function usePress(props) {
  let {
    onPress,
    onPressChange,
    onPressStart,
    onPressEnd,
    onPressUp,
    isDisabled,
    isPressed: isPressedProp,
    preventFocusOnPress,
    shouldCancelOnPointerExit,
    allowTextSelectionOnPress,
    ref: _,
    ...domProps
  } = usePressResponderContext(props);
  let [isPressed, setPressed] = useState(false);
  let ref = useRef({
    isPressed: false,
    ignoreEmulatedMouseEvents: false,
    ignoreClickAfterPress: false,
    didFirePressStart: false,
    isTriggeringEvent: false,
    activePointerId: null,
    target: null,
    isOverTarget: false,
    pointerType: null
  });
  let { addGlobalListener, removeAllGlobalListeners } = useGlobalListeners();
  let triggerPressStart = useEffectEvent((originalEvent, pointerType) => {
    let state = ref.current;
    if (isDisabled || state.didFirePressStart) {
      return;
    }
    let shouldStopPropagation = true;
    state.isTriggeringEvent = true;
    if (onPressStart) {
      let event = new PressEvent("pressstart", pointerType, originalEvent);
      onPressStart(event);
      shouldStopPropagation = event.shouldStopPropagation;
    }
    if (onPressChange) {
      onPressChange(true);
    }
    state.isTriggeringEvent = false;
    state.didFirePressStart = true;
    setPressed(true);
    return shouldStopPropagation;
  });
  let triggerPressEnd = useEffectEvent(
    (originalEvent, pointerType, wasPressed = true) => {
      let state = ref.current;
      if (!state.didFirePressStart) {
        return;
      }
      state.ignoreClickAfterPress = true;
      state.didFirePressStart = false;
      state.isTriggeringEvent = true;
      let shouldStopPropagation = true;
      if (onPressEnd) {
        let event = new PressEvent("pressend", pointerType, originalEvent);
        onPressEnd(event);
        shouldStopPropagation = event.shouldStopPropagation;
      }
      if (onPressChange) {
        onPressChange(false);
      }
      setPressed(false);
      if (onPress && wasPressed && !isDisabled) {
        let event = new PressEvent("press", pointerType, originalEvent);
        onPress(event);
        shouldStopPropagation && (shouldStopPropagation = event.shouldStopPropagation);
      }
      state.isTriggeringEvent = false;
      return shouldStopPropagation;
    }
  );
  let triggerPressUp = useEffectEvent((originalEvent, pointerType) => {
    let state = ref.current;
    if (isDisabled) {
      return;
    }
    if (onPressUp) {
      state.isTriggeringEvent = true;
      let event = new PressEvent("pressup", pointerType, originalEvent);
      onPressUp(event);
      state.isTriggeringEvent = false;
      return event.shouldStopPropagation;
    }
    return true;
  });
  let cancel = useEffectEvent((e) => {
    let state = ref.current;
    if (state.isPressed) {
      if (state.isOverTarget && state.target) {
        triggerPressEnd(createEvent(state.target, e), state.pointerType, false);
      }
      state.isPressed = false;
      state.isOverTarget = false;
      state.activePointerId = null;
      state.pointerType = null;
      removeAllGlobalListeners();
      if (!allowTextSelectionOnPress && state.target) {
        restoreTextSelection(state.target);
      }
    }
  });
  let cancelOnPointerExit = useEffectEvent((e) => {
    if (shouldCancelOnPointerExit) {
      cancel(e);
    }
  });
  let pressProps = useMemo(() => {
    let state = ref.current;
    let pressProps2 = {
      onKeyDown(e) {
        var _a;
        if (isValidKeyboardEvent(e.nativeEvent, e.currentTarget) && e.currentTarget.contains(e.target)) {
          if (shouldPreventDefaultKeyboard(e.target, e.key)) {
            e.preventDefault();
          }
          let shouldStopPropagation = true;
          if (!state.isPressed && !e.repeat) {
            state.target = e.currentTarget;
            state.isPressed = true;
            shouldStopPropagation = triggerPressStart(e, "keyboard");
            addGlobalListener(getOwnerDocument(e.currentTarget), "keyup", onKeyUp, false);
          }
          if (shouldStopPropagation) {
            e.stopPropagation();
          }
          if (e.metaKey && isMac()) {
            (_a = state.metaKeyEvents) == null ? void 0 : _a.set(e.key, e.nativeEvent);
          }
        } else if (e.key === "Meta") {
          state.metaKeyEvents = /* @__PURE__ */ new Map();
        }
      },
      onKeyUp(e) {
        if (state.target && isValidKeyboardEvent(e.nativeEvent, e.currentTarget) && !e.repeat && e.currentTarget.contains(e.target)) {
          triggerPressUp(createEvent(state.target, e), "keyboard");
        }
      },
      onClick(e) {
        if (e && !e.currentTarget.contains(e.target)) {
          return;
        }
        if (e && e.button === 0 && !state.isTriggeringEvent && !openLink.isOpening) {
          let shouldStopPropagation = true;
          if (isDisabled) {
            e.preventDefault();
          }
          if (!state.ignoreClickAfterPress && !state.ignoreEmulatedMouseEvents && !state.isPressed && (state.pointerType === "virtual" || isVirtualClick(e.nativeEvent))) {
            if (!isDisabled && !preventFocusOnPress) {
              focusWithoutScrolling(e.currentTarget);
            }
            let stopPressStart = triggerPressStart(e, "virtual");
            let stopPressUp = triggerPressUp(e, "virtual");
            let stopPressEnd = triggerPressEnd(e, "virtual");
            shouldStopPropagation = stopPressStart && stopPressUp && stopPressEnd;
          }
          state.ignoreEmulatedMouseEvents = false;
          state.ignoreClickAfterPress = false;
          if (shouldStopPropagation) {
            e.stopPropagation();
          }
        }
      }
    };
    let onKeyUp = (e) => {
      var _a, _b;
      if (state.isPressed && state.target && isValidKeyboardEvent(e, state.target)) {
        if (shouldPreventDefaultKeyboard(e.target, e.key)) {
          e.preventDefault();
        }
        let target = e.target;
        let shouldStopPropagation = triggerPressEnd(
          createEvent(state.target, e),
          "keyboard",
          state.target.contains(target)
        );
        removeAllGlobalListeners();
        if (shouldStopPropagation) {
          e.stopPropagation();
        }
        if (e.key !== "Enter" && isHTMLAnchorLink(state.target) && state.target.contains(target) && !e[LINK_CLICKED]) {
          e[LINK_CLICKED] = true;
          openLink(state.target, e, false);
        }
        state.isPressed = false;
        (_a = state.metaKeyEvents) == null ? void 0 : _a.delete(e.key);
      } else if (e.key === "Meta" && ((_b = state.metaKeyEvents) == null ? void 0 : _b.size)) {
        let events = state.metaKeyEvents;
        state.metaKeyEvents = null;
        for (let event of events.values()) {
          state.target && state.target.dispatchEvent(new KeyboardEvent("keyup", event));
        }
      }
    };
    if (typeof PointerEvent !== "undefined") {
      pressProps2.onPointerDown = (e) => {
        if (e.button !== 0 || !e.currentTarget.contains(e.target)) {
          return;
        }
        if (isVirtualPointerEvent(e.nativeEvent)) {
          state.pointerType = "virtual";
          return;
        }
        if (shouldPreventDefault(e.currentTarget)) {
          e.preventDefault();
        }
        state.pointerType = e.pointerType;
        let shouldStopPropagation = true;
        if (!state.isPressed) {
          state.isPressed = true;
          state.isOverTarget = true;
          state.activePointerId = e.pointerId;
          state.target = e.currentTarget;
          if (!isDisabled && !preventFocusOnPress) {
            focusWithoutScrolling(e.currentTarget);
          }
          if (!allowTextSelectionOnPress) {
            disableTextSelection(state.target);
          }
          shouldStopPropagation = triggerPressStart(e, state.pointerType);
          addGlobalListener(getOwnerDocument(e.currentTarget), "pointermove", onPointerMove, false);
          addGlobalListener(getOwnerDocument(e.currentTarget), "pointerup", onPointerUp, false);
          addGlobalListener(
            getOwnerDocument(e.currentTarget),
            "pointercancel",
            onPointerCancel,
            false
          );
        }
        if (shouldStopPropagation) {
          e.stopPropagation();
        }
      };
      pressProps2.onMouseDown = (e) => {
        if (!e.currentTarget.contains(e.target)) {
          return;
        }
        if (e.button === 0) {
          if (shouldPreventDefault(e.currentTarget)) {
            e.preventDefault();
          }
          e.stopPropagation();
        }
      };
      pressProps2.onPointerUp = (e) => {
        if (!e.currentTarget.contains(e.target) || state.pointerType === "virtual") {
          return;
        }
        if (e.button === 0 && isOverTarget(e, e.currentTarget)) {
          triggerPressUp(e, state.pointerType || e.pointerType);
        }
      };
      let onPointerMove = (e) => {
        if (e.pointerId !== state.activePointerId || !state.target) {
          return;
        }
        if (isOverTarget(e, state.target)) {
          if (!state.isOverTarget) {
            state.isOverTarget = true;
            triggerPressStart(createEvent(state.target, e), state.pointerType);
          }
        } else if (state.isOverTarget) {
          state.isOverTarget = false;
          triggerPressEnd(createEvent(state.target, e), state.pointerType, false);
          cancelOnPointerExit(e);
        }
      };
      let onPointerUp = (e) => {
        if (e.pointerId === state.activePointerId && state.isPressed && e.button === 0 && state.target) {
          if (isOverTarget(e, state.target)) {
            triggerPressEnd(createEvent(state.target, e), state.pointerType);
          } else if (state.isOverTarget) {
            triggerPressEnd(createEvent(state.target, e), state.pointerType);
          }
          state.isPressed = false;
          state.isOverTarget = false;
          state.activePointerId = null;
          state.pointerType = null;
          removeAllGlobalListeners();
          if (!allowTextSelectionOnPress) {
            restoreTextSelection(state.target);
          }
        }
      };
      let onPointerCancel = (e) => {
        cancel(e);
      };
      pressProps2.onDragStart = (e) => {
        if (!e.currentTarget.contains(e.target)) {
          return;
        }
        cancel(e);
      };
    } else {
      pressProps2.onMouseDown = (e) => {
        if (e.button !== 0 || !e.currentTarget.contains(e.target)) {
          return;
        }
        if (shouldPreventDefault(e.currentTarget)) {
          e.preventDefault();
        }
        if (state.ignoreEmulatedMouseEvents) {
          e.stopPropagation();
          return;
        }
        state.isPressed = true;
        state.isOverTarget = true;
        state.target = e.currentTarget;
        state.pointerType = isVirtualClick(e.nativeEvent) ? "virtual" : "mouse";
        if (!isDisabled && !preventFocusOnPress) {
          focusWithoutScrolling(e.currentTarget);
        }
        let shouldStopPropagation = triggerPressStart(e, state.pointerType);
        if (shouldStopPropagation) {
          e.stopPropagation();
        }
        addGlobalListener(getOwnerDocument(e.currentTarget), "mouseup", onMouseUp, false);
      };
      pressProps2.onMouseEnter = (e) => {
        if (!e.currentTarget.contains(e.target)) {
          return;
        }
        let shouldStopPropagation = true;
        if (state.isPressed && !state.ignoreEmulatedMouseEvents) {
          state.isOverTarget = true;
          shouldStopPropagation = triggerPressStart(e, state.pointerType);
        }
        if (shouldStopPropagation) {
          e.stopPropagation();
        }
      };
      pressProps2.onMouseLeave = (e) => {
        if (!e.currentTarget.contains(e.target)) {
          return;
        }
        let shouldStopPropagation = true;
        if (state.isPressed && !state.ignoreEmulatedMouseEvents) {
          state.isOverTarget = false;
          shouldStopPropagation = triggerPressEnd(e, state.pointerType, false);
          cancelOnPointerExit(e);
        }
        if (shouldStopPropagation) {
          e.stopPropagation();
        }
      };
      pressProps2.onMouseUp = (e) => {
        if (!e.currentTarget.contains(e.target)) {
          return;
        }
        if (!state.ignoreEmulatedMouseEvents && e.button === 0) {
          triggerPressUp(e, state.pointerType || "mouse");
        }
      };
      let onMouseUp = (e) => {
        if (e.button !== 0) {
          return;
        }
        state.isPressed = false;
        removeAllGlobalListeners();
        if (state.ignoreEmulatedMouseEvents) {
          state.ignoreEmulatedMouseEvents = false;
          return;
        }
        if (!state.target) {
          return;
        }
        if (isOverTarget(e, state.target)) {
          triggerPressEnd(createEvent(state.target, e), state.pointerType);
        } else if (state.isOverTarget) {
          triggerPressEnd(createEvent(state.target, e), state.pointerType, false);
        }
        state.isOverTarget = false;
      };
      pressProps2.onTouchStart = (e) => {
        if (!e.currentTarget.contains(e.target)) {
          return;
        }
        let touch = getTouchFromEvent(e.nativeEvent);
        if (!touch) {
          return;
        }
        state.activePointerId = touch.identifier;
        state.ignoreEmulatedMouseEvents = true;
        state.isOverTarget = true;
        state.isPressed = true;
        state.target = e.currentTarget;
        state.pointerType = "touch";
        if (!isDisabled && !preventFocusOnPress) {
          focusWithoutScrolling(e.currentTarget);
        }
        if (!allowTextSelectionOnPress) {
          disableTextSelection(state.target);
        }
        let shouldStopPropagation = triggerPressStart(e, state.pointerType);
        if (shouldStopPropagation) {
          e.stopPropagation();
        }
        addGlobalListener(getOwnerWindow(e.currentTarget), "scroll", onScroll, true);
      };
      pressProps2.onTouchMove = (e) => {
        if (!e.currentTarget.contains(e.target)) {
          return;
        }
        if (!state.isPressed) {
          e.stopPropagation();
          return;
        }
        let touch = getTouchById(e.nativeEvent, state.activePointerId);
        let shouldStopPropagation = true;
        if (touch && isOverTarget(touch, e.currentTarget)) {
          if (!state.isOverTarget) {
            state.isOverTarget = true;
            shouldStopPropagation = triggerPressStart(e, state.pointerType);
          }
        } else if (state.isOverTarget) {
          state.isOverTarget = false;
          shouldStopPropagation = triggerPressEnd(e, state.pointerType, false);
          cancelOnPointerExit(e);
        }
        if (shouldStopPropagation) {
          e.stopPropagation();
        }
      };
      pressProps2.onTouchEnd = (e) => {
        if (!e.currentTarget.contains(e.target)) {
          return;
        }
        if (!state.isPressed) {
          e.stopPropagation();
          return;
        }
        let touch = getTouchById(e.nativeEvent, state.activePointerId);
        let shouldStopPropagation = true;
        if (touch && isOverTarget(touch, e.currentTarget)) {
          triggerPressUp(e, state.pointerType);
          shouldStopPropagation = triggerPressEnd(e, state.pointerType);
        } else if (state.isOverTarget) {
          shouldStopPropagation = triggerPressEnd(e, state.pointerType, false);
        }
        if (shouldStopPropagation) {
          e.stopPropagation();
        }
        state.isPressed = false;
        state.activePointerId = null;
        state.isOverTarget = false;
        state.ignoreEmulatedMouseEvents = true;
        if (!allowTextSelectionOnPress && state.target) {
          restoreTextSelection(state.target);
        }
        removeAllGlobalListeners();
      };
      pressProps2.onTouchCancel = (e) => {
        if (!e.currentTarget.contains(e.target)) {
          return;
        }
        e.stopPropagation();
        if (state.isPressed) {
          cancel(e);
        }
      };
      let onScroll = (e) => {
        if (state.isPressed && e.target.contains(state.target)) {
          cancel({
            currentTarget: state.target,
            shiftKey: false,
            ctrlKey: false,
            metaKey: false,
            altKey: false
          });
        }
      };
      pressProps2.onDragStart = (e) => {
        if (!e.currentTarget.contains(e.target)) {
          return;
        }
        cancel(e);
      };
    }
    return pressProps2;
  }, [
    addGlobalListener,
    isDisabled,
    preventFocusOnPress,
    removeAllGlobalListeners,
    allowTextSelectionOnPress,
    cancel,
    cancelOnPointerExit,
    triggerPressEnd,
    triggerPressStart,
    triggerPressUp
  ]);
  useEffect(() => {
    return () => {
      if (!allowTextSelectionOnPress && ref.current.target) {
        restoreTextSelection(ref.current.target);
      }
    };
  }, [allowTextSelectionOnPress]);
  return {
    isPressed: isPressedProp || isPressed,
    pressProps: mergeProps(domProps, pressProps)
  };
}
function isHTMLAnchorLink(target) {
  return target.tagName === "A" && target.hasAttribute("href");
}
function isValidKeyboardEvent(event, currentTarget) {
  const { key, code } = event;
  const element = currentTarget;
  const role = element.getAttribute("role");
  return (key === "Enter" || key === " " || key === "Spacebar" || code === "Space") && !(element instanceof getOwnerWindow(element).HTMLInputElement && !isValidInputKey(element, key) || element instanceof getOwnerWindow(element).HTMLTextAreaElement || element.isContentEditable) && !((role === "link" || !role && isHTMLAnchorLink(element)) && key !== "Enter");
}
function getTouchFromEvent(event) {
  const { targetTouches } = event;
  if (targetTouches.length > 0) {
    return targetTouches[0];
  }
  return null;
}
function getTouchById(event, pointerId) {
  const changedTouches = event.changedTouches;
  for (let i = 0; i < changedTouches.length; i++) {
    const touch = changedTouches[i];
    if (touch.identifier === pointerId) {
      return touch;
    }
  }
  return null;
}
function createEvent(target, e) {
  return {
    currentTarget: target,
    shiftKey: e.shiftKey,
    ctrlKey: e.ctrlKey,
    metaKey: e.metaKey,
    altKey: e.altKey
  };
}
function getPointClientRect(point) {
  let offsetX = !!point.width ? point.width / 2 : point.radiusX || 0;
  let offsetY = !!point.height ? point.height / 2 : point.radiusY || 0;
  return {
    top: point.clientY - offsetY,
    right: point.clientX + offsetX,
    bottom: point.clientY + offsetY,
    left: point.clientX - offsetX
  };
}
function areRectanglesOverlapping(a, b) {
  if (a.left > b.right || b.left > a.right) {
    return false;
  }
  if (a.top > b.bottom || b.top > a.bottom) {
    return false;
  }
  return true;
}
function isOverTarget(point, target) {
  let rect = target.getBoundingClientRect();
  let pointRect = getPointClientRect(point);
  return areRectanglesOverlapping(rect, pointRect);
}
function shouldPreventDefault(target) {
  return !(target instanceof HTMLElement) || !target.hasAttribute("draggable");
}
function shouldPreventDefaultKeyboard(target, key) {
  if (target instanceof HTMLInputElement) {
    return !isValidInputKey(target, key);
  }
  if (target instanceof HTMLButtonElement) {
    return target.type !== "submit" && target.type !== "reset";
  }
  if (isHTMLAnchorLink(target)) {
    return false;
  }
  return true;
}
var nonTextInputTypes = /* @__PURE__ */ new Set([
  "checkbox",
  "radio",
  "range",
  "color",
  "file",
  "image",
  "button",
  "submit",
  "reset"
]);
function isValidInputKey(target, key) {
  return target.type === "checkbox" || target.type === "radio" ? key === " " : nonTextInputTypes.has(target.type);
}
export {
  usePress
};
