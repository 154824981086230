import React from 'react';
import { SignUp } from 'components/SignUp';
import { useWebAuth } from 'providers/WebAuthProvider';
import { initializeClient } from 'providers/WebAuthProvider.helpers';
import SigninContainer from 'components/SigninContainer';
import { SigninProvider } from 'providers/SigninProvider';
import PasswordRecoveryContainer from 'components/PasswordRecoveryContainer';

initializeClient();
export const Login = (): JSX.Element => {
  const { mode } = useWebAuth();
  return (
    <div>
      {mode === 'signUp' && <SignUp />}
      {mode === 'signIn' && (
        <SigninProvider>
          <SigninContainer />
        </SigninProvider>
      )}
      {mode === 'resetPassword' && (
        <SigninProvider>
          <PasswordRecoveryContainer />
        </SigninProvider>
      )}
    </div>
  );
};
