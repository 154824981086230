"use client";

// src/base/menu-section-base.tsx
import { BaseSection } from "@nextui-org/aria-utils";
var MenuSectionBase = BaseSection;
var menu_section_base_default = MenuSectionBase;

export {
  menu_section_base_default
};
