import React, { useEffect, useState } from 'react';
import { Button, rebrand, Auth0FormLayout } from '@flybuys/ui-kit';
import { AnalyticsV2 } from '@flybuys/analytics';
import VerificationCodeHelpModal from 'components/modals/VerificationCodeHelpModal/VerificationCodeHelpModal';
import './MutifactorAuthVerify.css';
import { TooManySMSError } from 'helpers/errorUtils';

const { Form } = rebrand;
const { Header, ErrorMessage, FormLayout } = Auth0FormLayout;

type MultifactorAuthVerifyProps = {
  phoneNumber: string;
  onVerifySms: (code: string) => void;
  onEditBtnClicked: () => void;
  onResendSms: () => void;
  loading: boolean;
  enrolled: boolean;
  error: string | undefined;
  allowRememberBrowser: boolean;
  invalidOtp: boolean;
  tooManySms: boolean;
  verificationCodeRequired: boolean;
  setVerificationCodeRequired: (value: boolean) => void;
  onAllowRememberBrowserUpdate: (val: boolean) => void;
};

const MultifactorAuthVerify = ({
  phoneNumber,
  onVerifySms,
  onEditBtnClicked,
  onResendSms,
  loading,
  enrolled,
  error,
  allowRememberBrowser,
  invalidOtp,
  tooManySms,
  verificationCodeRequired,
  setVerificationCodeRequired,
  onAllowRememberBrowserUpdate,
}: MultifactorAuthVerifyProps) => {
  const [verifyCode, setVerifyCode] = useState('');
  const isFbApp = localStorage.getItem('isFbApp') === 'true';
  const { allowRememberBrowserEnable } = window.mfConfig;
  const otpFieldRef = React.useRef();
  const mobileProps = {
    onChange: () => {},
    withPadding: false,
    value: phoneNumber,
    disabled: true,
  };
  const rememberMeCheckboxProps = {
    value: allowRememberBrowser,
    label: 'Remember me for 30 days',
    onChange: (v: boolean, checked: boolean) => {
      onAllowRememberBrowserUpdate(checked);
    },
    classNames: {
      input:
        'font-public-sans-lt text-bu-fb-body-small md:text-bu-fb-body-small-md font-light mt-12',
    },
  };

  useEffect(() => {
    if (otpFieldRef.current) {
      const otpField = otpFieldRef.current as HTMLInputElement;
      otpField.onpaste = e => e.preventDefault();
      otpField.ondrop = e => e.preventDefault();
    }
  }, []);
  const verifySmsHandler = () => {
    AnalyticsV2.trackEvent('MFA_VerifyMobileNumber', {
      pageInfo: {
        pageName: 'OTP',
        pagePath: '/mf',
        siteSection: 'OTP',
      },
      buttonInfo: {
        buttonName: 'Verify',
      },
    });
    onVerifySms(verifyCode);
  };
  const editBtnHandler = () => {
    onEditBtnClicked();
  };

  const handleEnter = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter') {
      verifySmsHandler();
    }
  };

  const resendCodeHandler = () => {
    AnalyticsV2.trackEvent('MFA_ResendCode', {
      buttonInfo: {
        buttonName: 'Resend code',
      },
    });
    onResendSms();
  };

  const formHeaderProps = {
    heading: 'Verify your identity',
    description: (
      <>
        We&apos;ve sent a text message to:
        <br />
        <VerificationCodeHelpModal />
      </>
    ),
  };

  const isEmptyInput = (empty: boolean) => {
    return empty && 'Verification code is required.';
  };

  return (
    <FormLayout>
      {tooManySms && <ErrorMessage message={<TooManySMSError />} />}
      {error && <ErrorMessage message={error} />}
      <Header {...formHeaderProps} />
      <form
        onSubmit={e => {
          e.preventDefault();
          return false;
        }}
        onKeyDown={handleEnter}
        role="presentation"
      >
        <div className="mobile-input-field mt-16">
          {!enrolled ? (
            <Form.MobileNoField {...mobileProps} />
          ) : (
            <Form.TextField disabled value={phoneNumber} />
          )}
        </div>
        {!enrolled && (
          <Button
            tertiary
            noArrow
            onClick={editBtnHandler}
            classNames="btn-edit-mobile"
            ariaLabel="Edit mobile number"
          >
            Edit
          </Button>
        )}
        <div className="verification-input-field mt-14">
          <Form.TextField
            label="Enter verification code"
            withPadding={false}
            ref={otpFieldRef}
            autoComplete
            errorMsg={
              invalidOtp || tooManySms || isEmptyInput(verificationCodeRequired)
            }
            inputMode="numeric"
            value={verifyCode}
            controlled
            maxLength={6}
            onChange={e => {
              const verificationCodePattern = /^\d{1,6}$/;
              const inputElement = e.target as HTMLInputElement;
              if (verificationCodePattern.test(inputElement.value)) {
                setVerificationCodeRequired(false);
                setVerifyCode(inputElement.value);
              } else {
                const value = inputElement.value.substring(
                  0,
                  inputElement.value.length - 1
                );
                setVerifyCode(value);
              }
            }}
          />
          <Button
            tertiary
            noArrow
            classNames="btn-edit-mobile"
            ariaLabel="Edit mobile number"
            onClick={resendCodeHandler}
          >
            Resend code
          </Button>
          {!isFbApp && allowRememberBrowserEnable !== 'false' && (
            <div className="mt-4">
              <Form.CheckboxField {...rememberMeCheckboxProps} />
            </div>
          )}
        </div>
        <div className="fb-layout-cta mb-16">
          <Button
            loading={loading}
            noArrow
            classNames="mt-4 fb-btn-stories-btn-lg w-full"
            onClick={() => verifySmsHandler()}
            ariaLabel="Primary large"
          >
            Verify
          </Button>
        </div>
      </form>
    </FormLayout>
  );
};
export default MultifactorAuthVerify;
