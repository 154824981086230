export const dummyAuth0Config = {
  icon: 'https://register.flybuys.com.au/cdn/edm/template/vanish3.5/header/flybuys.png',
  assetsUrl: '',
  auth0Domain: 'flybuys-dev.au.auth0.com',
  auth0Tenant: 'flybuys-dev',
  clientConfigurationBaseUrl: 'https://flybuys-dev.au.auth0.com/',
  callbackOnLocationHash: false,
  callbackURL: 'http://localhost:3000',
  connection: 'Username-Password-Authentication',
  cdn: 'https://cdn.auth0.com/',
  clientID: 'partner-catch-web-test',
  dict: {
    signin: {
      title: 'KavithaTestAppSample',
    },
  },
  extraParams: {
    protocol: 'oauth2',
    audience: 'https://flybuys-myaccount-dev',
    scope:
      'openid profile email tokens:create:all orders:create orders:read:member members:me',
    response_type: 'code',
    response_mode: 'query',
    nonce: 'alZXd3dhOTZtTjRKUFBNdHRwNnVIUn5TUGtUWWVXSTFpc09YU2txSUpSMA==',
    code_challenge: 'hl5d4g5r6dNVwP2cnZVDSw2CB3o8-3hZL78cYb0hbSY',
    code_challenge_method: 'S256',
    auth0Client: 'eyJuYW1lIjoiYXV0aDAtcmVhY3QiLCJ2ZXJzaW9uIjoiMS4xMi4wIn0=',
    _csrf: 'BPFxr6Ro-t3FP2wKT2Yg00CO6xz5MNy7cKZI',
    _intstate: 'deprecated',
    state:
      'hKFo2SBBZnRqMk52MGNxMUhNdTIyb0JQTXVMQUFjMlF4Q1lLX6FupWxvZ2luo3RpZNkgZTFRNFE1aHNYd1BnRUJrcnpvTktQWTE0dVZsQmd2M0ujY2lktEthdml0aGFUZXN0QXBwU2FtcGxl',
  },
  internalOptions: {
    protocol: 'oauth2',
    audience: 'https://flybuys-myaccount-dev',
    scope:
      'openid profile email tokens:create:all orders:create orders:read:member members:me',
    response_type: 'code',
    response_mode: 'query',
    nonce: 'alZXd3dhOTZtTjRKUFBNdHRwNnVIUn5TUGtUWWVXSTFpc09YU2txSUpSMA==',
    code_challenge: 'hl5d4g5r6dNVwP2cnZVDSw2CB3o8-3hZL78cYb0hbSY',
    code_challenge_method: 'S256',
    auth0Client: 'eyJuYW1lIjoiYXV0aDAtcmVhY3QiLCJ2ZXJzaW9uIjoiMS4xMi4wIn0=',
    _csrf: 'BPFxr6Ro-t3FP2wKT2Yg00CO6xz5MNy7cKZI',
    _intstate: 'deprecated',
    state:
      'hKFo2SBBZnRqMk52MGNxMUhNdTIyb0JQTXVMQUFjMlF4Q1lLX6FupWxvZ2luo3RpZNkgZTFRNFE1aHNYd1BnRUJrcnpvTktQWTE0dVZsQmd2M0ujY2lktEthdml0aGFUZXN0QXBwU2FtcGxl',
    membership_num: '',
    registration_num: '',
  },
  widgetUrl: 'https://cdn.auth0.com/w2/auth0-widget-5.1.min.js',
  isThirdPartyClient: false,
  authorizationServer: {
    url: 'https://flybuys-dev.au.auth0.com',
    issuer: 'https://flybuys-dev.au.auth0.com/',
  },
  colors: {
    page_background: '#007cc2',
    primary: '#007cc2',
  },
};

export const dummyPrConfig = {
  _csrf: 'dummyscrf',
  ticket: 'dummyticket',
  email: 'dummy@email.com',
};
