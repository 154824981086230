import { WebAuth } from 'auth0-js';

export type Mode = 'signIn' | 'signUp' | 'resetPassword';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    prConfig: any;
  }
}

export const getLoginAction = (mode: Mode) =>
  mode === 'signUp' ? 'register' : 'signin';

export const createAuthClient = () => {
  if (!window.auth0Config?.auth0Tenant) {
    throw new Error('Invalid Auth0 config.');
  }

  const params = {
    overrides: {
      __tenant: window.auth0Config.auth0Tenant,
      // eslint-disable-next-line camelcase
      __token_issuer: window.auth0Config.authorizationServer.issuer,
    },
    domain: window.auth0Config.auth0Domain,
    clientID: window.auth0Config.clientID,
    redirectUri: window.auth0Config.callbackURL,
    responseType: 'code',
    ...window.auth0Config.internalOptions,
  };

  return new WebAuth(params);
};

export const initializeClient = () => {
  if (!window.auth0Config || !window.auth0Config.clientID) return;
  // save flag to check if login is opened through app
  localStorage.setItem(
    'isFbApp',
    window.auth0Config?.clientID.includes('flybuys-ios') ||
      window.auth0Config?.clientID.includes('flybuys-android')
  );
};
