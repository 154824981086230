import React from 'react';
import { rebrand, Auth0FormLayout } from '@flybuys/ui-kit';
import { AnalyticsV2 } from '@flybuys/analytics';
import { getHost } from 'helpers/urls';
import { HostTypes } from '@flybuys/utils';

const { Button } = rebrand;
const { Header, FormLayout } = Auth0FormLayout;

export const PasswordResetSuccess = () => {
  const formHeaderProps = {
    heading: 'Password Reset',
    description: 'Your Flybuys account password has been successfully updated.',
    classNames: 'mt-10 mb-20',
  };

  return (
    <FormLayout>
      <img
        style={{
          marginTop: '15px',
        }}
        alt="Success Logo"
        src="https://register.flybuys.com.au/cdn/edm/library/trigger/operational/success.svg"
      />
      <Header {...formHeaderProps} />
      <Button
        variant="primary"
        size="large"
        className="mt-20 w-full"
        fluid
        onClick={() => {
          AnalyticsV2.trackEvent('PasswordResetSignIn', {
            buttonInfo: {
              buttonName: 'Sign in',
              buttonLink: '',
            },
          });
          window.location.replace(
            `${getHost(HostTypes.MY_ACCOUNT)}/logout?redirectUri=${getHost(
              HostTypes.MY_ACCOUNT
            )}`
          );
        }}
        noArrow
      >
        Sign in
      </Button>

      {/* make sure user is logged out in Ping */}
      <iframe
        className="h-0 w-0"
        src="https://id.flybuys.com.au/idp/startSLO.ping"
        title="log out"
      />
    </FormLayout>
  );
};
