"use client";
import {
  usePopover
} from "./chunk-U6L5DB4F.mjs";

// src/free-solo-popover.tsx
import * as React from "react";
import { DismissButton, Overlay } from "@react-aria/overlays";
import { forwardRef } from "@nextui-org/system";
import { motion } from "framer-motion";
import { mergeProps } from "@react-aria/utils";
import { getTransformOrigins } from "@nextui-org/aria-utils";
import { TRANSITION_VARIANTS } from "@nextui-org/framer-transitions";
import { jsx, jsxs } from "react/jsx-runtime";
var FreeSoloPopoverWrapper = ({
  children,
  motionProps,
  placement,
  disableAnimation,
  style = {},
  ...otherProps
}) => {
  return disableAnimation ? /* @__PURE__ */ jsx("div", { ...otherProps, children }) : /* @__PURE__ */ jsx(
    motion.div,
    {
      animate: "enter",
      exit: "exit",
      initial: "initial",
      style: {
        ...style,
        ...getTransformOrigins(placement === "center" ? "top" : placement)
      },
      variants: TRANSITION_VARIANTS.scaleSpringOpacity,
      ...mergeProps(otherProps, motionProps),
      children
    }
  );
};
var FreeSoloPopover = forwardRef((props, ref) => {
  const {
    Component,
    state,
    children,
    placement,
    backdrop,
    portalContainer,
    disableAnimation,
    motionProps,
    isNonModal,
    getPopoverProps,
    getBackdropProps,
    getDialogProps,
    getContentProps
  } = usePopover({
    ...props,
    shouldCloseOnInteractOutside: void 0,
    ref
  });
  const backdropContent = React.useMemo(() => {
    if (backdrop === "transparent") {
      return null;
    }
    if (disableAnimation) {
      return /* @__PURE__ */ jsx("div", { ...getBackdropProps() });
    }
    return /* @__PURE__ */ jsx(
      motion.div,
      {
        animate: "enter",
        exit: "exit",
        initial: "exit",
        variants: TRANSITION_VARIANTS.fade,
        ...getBackdropProps()
      }
    );
  }, [backdrop, disableAnimation, getBackdropProps]);
  return /* @__PURE__ */ jsxs(Overlay, { portalContainer, children: [
    !isNonModal && backdropContent,
    /* @__PURE__ */ jsx(Component, { ...getPopoverProps(), children: /* @__PURE__ */ jsxs(
      FreeSoloPopoverWrapper,
      {
        disableAnimation,
        motionProps,
        placement,
        tabIndex: -1,
        ...getDialogProps(),
        children: [
          !isNonModal && /* @__PURE__ */ jsx(DismissButton, { onDismiss: state.close }),
          /* @__PURE__ */ jsx("div", { ...getContentProps(), children }),
          /* @__PURE__ */ jsx(DismissButton, { onDismiss: state.close })
        ]
      }
    ) })
  ] });
});
FreeSoloPopover.displayName = "NextUI.FreeSoloPopover";
var free_solo_popover_default = FreeSoloPopover;

export {
  free_solo_popover_default
};
