"use client";

// src/use-dropdown.ts
import { useMenuTriggerState } from "@react-stately/menu";
import { useMenuTrigger } from "@react-aria/menu";
import { dropdown } from "@nextui-org/theme";
import { clsx } from "@nextui-org/shared-utils";
import { mergeRefs } from "@nextui-org/react-utils";
import { useMemo, useRef } from "react";
import { mergeProps } from "@react-aria/utils";
function useDropdown(props) {
  const {
    as,
    triggerRef: triggerRefProp,
    isOpen,
    defaultOpen,
    onOpenChange,
    type = "menu",
    trigger = "press",
    placement = "bottom",
    isDisabled = false,
    closeOnSelect = true,
    shouldBlockScroll = true,
    classNames: classNamesProp,
    disableAnimation = false,
    onClose,
    className,
    ...otherProps
  } = props;
  const Component = as || "div";
  const triggerRef = useRef(null);
  const menuTriggerRef = triggerRefProp || triggerRef;
  const menuRef = useRef(null);
  const popoverRef = useRef(null);
  const state = useMenuTriggerState({
    trigger,
    isOpen,
    defaultOpen,
    onOpenChange: (isOpen2) => {
      onOpenChange == null ? void 0 : onOpenChange(isOpen2);
      if (!isOpen2) {
        onClose == null ? void 0 : onClose();
      }
    }
  });
  const { menuTriggerProps, menuProps } = useMenuTrigger(
    { type, trigger, isDisabled },
    state,
    menuTriggerRef
  );
  const classNames = useMemo(
    () => dropdown({
      className
    }),
    [className]
  );
  const onMenuAction = (menuCloseOnSelect) => {
    if (menuCloseOnSelect !== void 0 && !menuCloseOnSelect) {
      return;
    }
    if (closeOnSelect) {
      state.close();
    }
  };
  const getPopoverProps = (props2 = {}) => ({
    state,
    placement,
    ref: popoverRef,
    disableAnimation,
    shouldBlockScroll,
    scrollRef: menuRef,
    triggerRef: menuTriggerRef,
    ...mergeProps(otherProps, props2),
    classNames: {
      ...classNamesProp,
      ...props2.classNames,
      content: clsx(classNames, classNamesProp == null ? void 0 : classNamesProp.content, props2.className)
    }
  });
  const getMenuTriggerProps = (originalProps = {}, _ref = null) => {
    const { onKeyDown, onPress, onPressStart, ...otherMenuTriggerProps } = menuTriggerProps;
    return {
      ...mergeProps(otherMenuTriggerProps, { isDisabled: props.isDisabled, originalProps }),
      ref: mergeRefs(_ref, triggerRef)
    };
  };
  const getMenuProps = (props2, _ref = null) => {
    return {
      ref: mergeRefs(_ref, menuRef),
      menuProps,
      ...mergeProps(props2, { onAction: () => onMenuAction(props2 == null ? void 0 : props2.closeOnSelect) })
    };
  };
  return {
    Component,
    menuRef,
    menuProps,
    classNames,
    closeOnSelect,
    onClose: state.close,
    autoFocus: state.focusStrategy || true,
    disableAnimation,
    getPopoverProps,
    getMenuProps,
    getMenuTriggerProps
  };
}

export {
  useDropdown
};
