import { SigninContext } from 'providers/SigninProvider';
import React, { useContext } from 'react';
import { PasswordRecovery } from './PasswordRecovery';
import { SigninOtp } from './SigninOtp';

const PasswordRecoveryContainer = () => {
  const { screen } = useContext(SigninContext);
  let container = <PasswordRecovery />;
  if (screen === 'withOtp') {
    container = <SigninOtp />;
  }
  return container;
};
export default PasswordRecoveryContainer;
