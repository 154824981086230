import React, { useState } from 'react';
import { rebrand, Auth0FormLayout } from '@flybuys/ui-kit';
import { validators } from '@flybuys/utils';
import { AnalyticsV2 } from '@flybuys/analytics';
import { handleEmailBlur } from 'helpers/forms';
import { useWebAuth } from 'providers/WebAuthProvider';
import { getPartnerClient, getClientId } from 'helpers/login';
import { PasswordRecoveryCheckMail } from './PasswordRecoveryCheckMail';
import Separator from './UiHelpers/Separator';
import { BackButton } from '@flybuys/ui-kit';
import { useSigninContext } from 'providers/SigninProvider';
import { Button } from '@flybuys/ui-kit';
import { events } from '../adobe/otp_events';

const { Form } = rebrand;
const { Header, ErrorMessage, FormLayout } = Auth0FormLayout;

export const PasswordRecovery = (): JSX.Element => {
  const isUnlinking =
    new URLSearchParams(window.location.search).get('login_action') ===
    'delink';
  const partnerClient = getPartnerClient();
  const {
    changePassword,
    isBusy,
    authError,
    startPasswordless,
    setMode,
    otpError,
    setOtpError,
  } = useWebAuth();
  const isPasswordReseLink = /[&?]password_reset=true/iu.test(
    window.location.search
  );
  const { setScreen, setUsernameOrEmail } = useSigninContext();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [showPasswordRecovery, setShowPasswordRecovery] = useState(true);
  const [showPasswordRecoveryCheckMail, setShowPasswordRecoveryCheckMail] =
    useState(false);
  const onContinue = () => {
    if (email.trim().length === 0) {
      setEmailError('Email is required.');
      return;
    }
    if (emailError) return;
    AnalyticsV2.trackEvent(
      // eslint-disable-next-line no-nested-ternary
      partnerClient
        ? isUnlinking
          ? 'PartnerUnlinking_ForgotPasswordContinue'
          : 'PartnerLinking_ForgotPasswordContinue'
        : 'Login_ForgotPasswordContinue',
      {
        pageInfo: {
          clientID: getClientId(),
          pageName: 'login',
          pagePath: '/',
          siteSection: 'login',
        },
        buttonInfo: {
          buttonName: 'Continue',
          buttonLink: '',
        },
      }
    );
    changePassword(email, err => {
      if (err) {
        return;
      }
      setShowPasswordRecovery(false);
      setShowPasswordRecoveryCheckMail(true);
    });
  };
  const sendVerificationMail = () => {
    changePassword(email, () => {});
  };
  const setCustomEmailError = errorMsg => {
    if (!errorMsg) {
      setEmailError('');
      return;
    }
    // The error message coming from email validator is
    // Email is required. It will be used each time you sign in.
    // Here we set custom error message
    if (errorMsg && errorMsg.includes('Email is required.')) {
      setEmailError('Email is required.');
    } else {
      setEmailError(errorMsg);
    }
  };

  const formMessageProps = {
    message: authError?.message,
  };

  const formHeaderProps = {
    heading: 'Forgot your password?',
    description:
      'Enter your email and we will send you instructions to reset your password.',
  };
  return (
    <>
      {showPasswordRecovery && (
        <FormLayout
          topComponent={
            <div className="mx-10 my-4">
              {!isPasswordReseLink && (
                <BackButton
                  classNames="!text-fb-darkBlue underline"
                  onClick={() => {
                    AnalyticsV2.trackEvent(
                      // eslint-disable-next-line no-nested-ternary
                      partnerClient
                        ? isUnlinking
                          ? 'PartnerUnlinking_BackToSignIn'
                          : 'PartnerLinking_BackToSignIn'
                        : 'Login_BackToSignIn',
                      {
                        pageInfo: {
                          clientID: getClientId(),
                          pageName: 'login',
                          pagePath: '/',
                          siteSection: 'login',
                        },
                        buttonInfo: {
                          buttonName: 'Back to sign in',
                          buttonLink: '',
                        },
                      }
                    );
                    setMode('signIn');
                  }}
                >
                  Back
                </BackButton>
              )}
            </div>
          }
        >
          {authError?.message && <ErrorMessage {...formMessageProps} />}
          <Header {...formHeaderProps} />
          <div className="mt-24">
            <Form.EmailField
              errorMsg={emailError || otpError}
              onChange={e => setEmail(e.target.value)}
              onBlur={handleEmailBlur(
                validators.validateEmail,
                setCustomEmailError
              )}
            />
            <Button
              color="primary"
              noArrow
              size="large"
              classNames="w-full"
              fluid
              onClick={onContinue}
              loading={isBusy}
            >
              Continue
            </Button>
            {!getPartnerClient() && (
              <>
                <Separator />
                <Button
                  id="btn-otp"
                  disabled={isBusy}
                  secondary
                  classNames="w-full"
                  noArrow
                  dataTestId="btn-login-otp"
                  ariaLabel="Sign in with a one-time code"
                  onClick={() => {
                    if (email.trim().length === 0) {
                      setEmailError('Email is required.');
                      return;
                    }
                    if (emailError) return;
                    events.signInWithOtpBtnClicked();
                    setUsernameOrEmail(email);
                    setOtpError('');
                    startPasswordless(email, err => {
                      if (err) return;
                      setScreen('withOtp');
                    });
                  }}
                >
                  Sign in without a password
                </Button>
              </>
            )}
          </div>
        </FormLayout>
      )}

      {showPasswordRecoveryCheckMail && (
        <PasswordRecoveryCheckMail
          authError={authError}
          email={email}
          loading={isBusy}
          resendMail={sendVerificationMail}
        />
      )}
    </>
  );
};
