import React from 'react';
import { Login } from 'pages/login';
import { WebAuthProvider } from 'providers/WebAuthProvider';
import { Multifactor } from 'pages/multifactor';
import { PasswordReset } from 'pages/passwordReset';
import MetaPage from 'components/MetaPage';

export function App() {
  const isMFA = window.location.pathname.includes('/mf');
  const isPasswordReset = window.location.pathname.includes('/lo/reset');

  return (
    <>
      <MetaPage />
      {isMFA && <Multifactor />}
      {isPasswordReset && <PasswordReset />}
      {!isMFA && !isPasswordReset && (
        <WebAuthProvider>
          <Login />
        </WebAuthProvider>
      )}
    </>
  );
}
