import React, { useState, useEffect, FC, ChangeEvent, Dispatch } from 'react';
import { Button, rebrand, Auth0FormLayout } from '@flybuys/ui-kit';
import { AnalyticsV2 } from '@flybuys/analytics';
import { apis, forms, PasswordFormContext } from '@flybuys/utils';
import { PasswordResetSuccess } from 'components/PasswordResetSuccess';
import {
  PasswordResetErrorsMessages,
  PasswordResetErrorsName,
} from 'metadata/errors';
import { handlePasswordResetSubmit } from 'helpers/passwordReset.helpers';

const { Form } = rebrand;
const { Header, FormLayout } = Auth0FormLayout;

const {
  handlePasswordBlur,
  handlePasswordToggle,
  usePasswordForm,
  handlePasswordChange,
} = forms;

export const PasswordReset: FC = () => {
  const passwordFieldRef = React.useRef();
  const form = usePasswordForm();
  const { loading } = form;
  const { prConfig } = window;
  const [passwordSuccess, setPasswordSuccess] = useState(false);
  const { chars, number, upperLower, specialChars, noSpace } =
    form.passwordErrors;

  const validateInput = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.value) handlePasswordBlur(form)(e);

    if (
      /(?=.*[!@#$%^&*?])/.test(form.password) &&
      !chars &&
      !number &&
      !upperLower &&
      !noSpace &&
      specialChars
    ) {
      form.setFormError('');
    }
  };

  const passwordProps = {
    value: form.password,
    maxLength: 20,
    onChange: handlePasswordChange(form),
    onBlur: validateInput,
    handlePasswordToggle: handlePasswordToggle(form),
    passwordToggle: form.passwordToggle,
    withPadding: false,
    errorMsg: form.formError,
    ref: passwordFieldRef,
    autoFocus: true,
  };
  useEffect(() => {
    if (passwordFieldRef.current) {
      const passwordField = passwordFieldRef.current as HTMLInputElement;
      passwordField.onpaste = e => e.preventDefault();
      passwordField.oncopy = e => e.preventDefault();
      if (passwordField.nextElementSibling) {
        passwordField.nextElementSibling.innerHTML = 'New Password';
      }
    }
  }, []);

  const formHeaderProps = {
    heading: 'Reset password',
    description: 'Update your password',
    classNames: 'mt-10 mb-20',
  };

  return prConfig && !passwordSuccess ? (
    <FormLayout>
      <Header {...formHeaderProps} />
      <form
        onSubmit={e => {
          handlePasswordResetSubmit(e, form, setPasswordSuccess);
        }}
      >
        {/* <!-- Do not remove this.  This protects against CSRF vulnerabilities --> */}
        <input type="hidden" name="_csrf" value="{{csrf_token}}" />
        <input type="hidden" name="ticket" value="{{ticket}}" />
        <input type="hidden" name="email" value="{{email}}" />
        <Form.PasswordField {...passwordProps} value={form.password} />
        <Form.PasswordRules
          errors={form.passwordErrors}
          isPasswordFieldTouched={form.touched}
        />
        <Button
          type="button"
          variant="primary"
          size="small"
          classNames="mt-10 w-full"
          fluid
          onClick={(e: ChangeEvent<HTMLInputElement>) => {
            validateInput(e);
            handlePasswordResetSubmit(e, form, setPasswordSuccess);
          }}
          noArrow
          loading={loading}
        >
          Reset password
        </Button>
      </form>
    </FormLayout>
  ) : (
    <PasswordResetSuccess />
  );
};
