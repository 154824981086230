import { EnrollmentErrors } from 'metadata/errors';
import { validators } from '@flybuys/utils';
import { AnalyticsV2 } from '@flybuys/analytics';

declare let guardianjs: any;
declare let auth0GuardianJS: any;

export const initializeMFA = (
  setPageLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setShowMultifactorAuth: React.Dispatch<React.SetStateAction<boolean>>,
  setShowMultifactorAuthVerify: React.Dispatch<React.SetStateAction<boolean>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setIsEnrolled: React.Dispatch<React.SetStateAction<boolean>>,
  showError: (err: any) => void,
  showEnrollmentError: (message: string) => void,
  setPhoneNumberVal: React.Dispatch<React.SetStateAction<string>>,
  mfaRuntimeState: any
) => {
  const mfState = mfaRuntimeState;
  setPageLoading(true);
  // handling local environment
  if (!guardianjs) {
    setShowMultifactorAuth(true);
    setPageLoading(false);
    return;
  }
  // start's the guardian transaction
  guardianjs.start((err, transaction) => {
    if (err) {
      showError(err);
      return;
    }
    mfState.transaction = transaction;
    transaction.on('error', txErr => {
      setLoading(false);
      showError(txErr);
    });

    transaction.on('timeout', () => {
      showEnrollmentError(EnrollmentErrors.timeout);
      // window.location.reload();
    });

    transaction.on('enrollment-complete', () => {
      setLoading(false);
    });

    transaction.on('auth-response', payload => {
      setLoading(false);
      if (!payload.accepted) {
        showEnrollmentError(EnrollmentErrors.auth_rejected);
        return;
      }
      localStorage.removeItem('isFbApp');
      auth0GuardianJS.formPostHelper(window.location.href, {
        signature: payload.signature,
        rememberBrowser: mfaRuntimeState.allowRememberBrowser,
      });
    });
    if (transaction.isEnrolled()) {
      setShowMultifactorAuth(false);
      setShowMultifactorAuthVerify(true);
      setIsEnrolled(true);
      const enrollments = transaction.getEnrollments();
      setPhoneNumberVal(enrollments[0].data.phoneNumber);
      transaction.requestAuth(
        enrollments[0],
        {
          method: enrollments[0].getAvailableMethods()[0],
        },
        (responseErr, authFlow) => {
          if (responseErr) {
            showError(responseErr);
            return;
          }
          mfState.authFlow = authFlow;
        }
      );
      setPageLoading(false);
      return;
    }
    setPageLoading(false);
    setShowMultifactorAuth(true);
  });
};

export const handleMobileNoChange = (
  event: React.KeyboardEvent<HTMLFormElement> | undefined,
  mobileNo: string,
  setErrorMessage: React.Dispatch<React.SetStateAction<string | undefined>>,
  mobileSubmitBtn: React.MutableRefObject<undefined> | undefined
) => {
  const errorMsg = validators.validateMobile(mobileNo);
  if (
    !errorMsg &&
    event &&
    event.key === 'Enter' &&
    mobileSubmitBtn &&
    mobileSubmitBtn.current
  ) {
    (mobileSubmitBtn.current as HTMLButtonElement).click();
  }
  setErrorMessage(errorMsg);
};

export const handleSubmit = (
  mobileNo: string,
  errorMessage: string | undefined,
  setErrorMessage: React.Dispatch<React.SetStateAction<string | undefined>>,
  onSendVerificationCode: (mobileNumber: string) => void
) => {
  AnalyticsV2.trackEvent('MFA_EnterMobileNumber', {
    buttonInfo: {
      buttonName: 'Send verification code',
      buttonLink: '',
    },
  });
  const errorMsg = validators.validateMobile(mobileNo);
  setErrorMessage(errorMsg);
  if (mobileNo.length === 0 || (errorMessage && errorMessage.length !== 0))
    return;
  onSendVerificationCode(mobileNo.replace(/ +/g, ''));
};
