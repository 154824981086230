export const errors = {
  local: [
    'error_sending_push_notification',
    'push_notification_wrong_credentials',
    'invalid_otp',
    'invalid_recovery_code',
    'invalid_body',
    'invalid_query_string',
    'invalid_phone_number',
    'error_sending_sms',
    'pn_endpoint_disabled',
    'too_many_sms',
    'too_many_pn',
    'too_many_sms_per_tenant',
    'too_many_pn_per_tenant',
    'invalid_otp_format',
    'invalid_recovery_code_format',
  ],
  global: [
    'invalid_token',
    'insufficient_scope',
    'invalid_bearer_format',
    'enrollment_conflict',
    'tenant_not_found',
    'login_transaction_not_found',
    'enrollment_transaction_not_found',
    'feature_disabled',
    'feature_disabled_by_admin',
    'method_not_found',
    'no_method_available',
    'enrollment_method_disabled',
    'auth_method_disabled',
    'transaction_expired',
    'already_enrolled',
    'not_enrolled',
  ],
};

export const EnrollmentErrors = {
  default: 'Something went wrong, please try again later.',
  timeout:
    "Seems that you've taken too long to login. Please click Resend code button to get new otp ",
  auth_rejected: 'Authentication has been rejected',
  already_enrolled: 'You are already enrolled',
};

export enum PasswordResetErrorsName {
  PASSWORD_STRENGTH_ERROR = 'PasswordStrengthError',
  PASSWORD_HISTORY_ERROR = 'PasswordHistoryError',
  PASSWORD_DICTIONARY_ERROR = 'PasswordDictionaryError',
  PASSWORD_NO_USER_INFO_ERROR = 'PasswordNoUserInfoError',
  PASSWORD_MISMATCH_ERROR = 'PasswordMismatchError',
}

export const PasswordResetErrorsMessages: {
  [x in PasswordResetErrorsName]: string;
} = {
  PasswordStrengthError:
    'Sorry, your password is weak and easily guessed by hackers. Please avoid common words or personal information like birthdates or names. Ensure that your password contains a mix of uppercase and lowercase letters, numbers, special characters and at least 8 characters long.',
  PasswordHistoryError:
    "Sorry, the password you used is already in use. Please choose a new, secure password that hasn't been used before. Use a mix of upper and lowercase letters, numbers, at least 8 characters long and avoid easily guessable information like birthdates or names.",
  PasswordDictionaryError: 'Password is not allowed, it might be too common.',
  PasswordNoUserInfoError:
    'Sorry, your password is weak and easily guessed by hackers. Please use a stronger password with a mix of upper and lowercase letters, numbers, and avoid common words or personal information like birthdates or names.',
  PasswordMismatchError: "Passwords Don't Match",
};
