import React from 'react';
import { Modal, Heading, rebrand, ButtonLink } from '@flybuys/ui-kit';
import { getHost } from 'helpers/urls';

const { BodyText } = rebrand;

export default function WhyDOB() {
  const mProps = {
    openLabel: 'Why?',
    contentLabel: '',
    heading: '',
    openerClassNames: 'ml-2 why',
    appElement: 'body',
  };
  return (
    <div className="fb-field-item inline">
      <Modal {...mProps}>
        <Heading classNames="fb-h5 md:fb-h4 inline">
          Why do we need your date of birth?
        </Heading>
        <BodyText>
          Our customer service team use your date of birth to verify your
          identity when you call us. We take your privacy seriously, and will
          never share this information. See our
          <ButtonLink
            noArrow
            plain
            href={`${getHost('experience')}/policies/privacy-policy/`}
            target="_blank"
            classNames="inline-flex text-fb-darkBlue underline"
          >
            privacy policy
          </ButtonLink>
          for more.
        </BodyText>
      </Modal>
    </div>
  );
}
