import React, { FC, ReactNode } from 'react';
import type { Auth0Error } from 'auth0-js';
import { ButtonLink, Button, rebrand } from '@flybuys/ui-kit';
import { getHost } from 'helpers/urls';
import { Mode } from 'providers/WebAuthProvider.helpers';
import { HostTypes } from '@flybuys/utils';
import { AnalyticsV2 } from '@flybuys/analytics';

const { Button: RebrandButton } = rebrand;
export const ContactUsLink: FC<{ firstLetterUppercase?: boolean }> = props => {
  const { firstLetterUppercase } = props;
  return (
    <ButtonLink
      noArrow
      target="_blank"
      plain
      href={`${getHost(HostTypes.EXPERIENCE)}/send-enquiry`}
      classNames="inline-flex text-fb-darkBlue underline"
      ariaLabel="contact us"
    >
      {firstLetterUppercase ? 'Contact us' : 'contact us'}
    </ButtonLink>
  );
};

export const ReJoinLink: FC = () => (
  <ButtonLink
    noArrow
    plain
    href={`${getHost(HostTypes.MY_ACCOUNT)}/join`}
    classNames="inline-flex text-fb-darkBlue underline"
    ariaLabel="rejoin now"
  >
    rejoin now
  </ButtonLink>
);

const GenericError: FC = () => (
  <>
    There was an error processing your request or <ContactUsLink />.
    Alternatively, you can try login with your 16 digit card number and password
  </>
);
export const TooManySMSError: FC = () => (
  <>
    You&apos;ve reached the maximum number of one-time pin requests for this
    session. Please try again in one hour or <ContactUsLink /> for support.
  </>
);
export const AccountBlockedError: FC = () => (
  <>
    Your account is closed. <ContactUsLink firstLetterUppercase /> to reopen or{' '}
    <ReJoinLink />.
  </>
);
export const AppleAccountLinkingError: FC = () => (
  <>
    Your social account doesn&apos;t match with your Flybuys account email. Sign
    in using your Flybuys email, then update the email connected to your Flybuys
    account to your Apple ID email from Account Details.
  </>
);
export const GoogleAccountLinkingError: FC = () => (
  <>
    Your social account doesn&apos;t match with your Flybuys account email. Sign
    in using your Flybuys email, then update the email connected to your Flybuys
    account to your Google account email from Account Details.
  </>
);
export const SocialLinkingGenericError: FC = () => (
  <>
    Looks like there&apos;s a problem on our end, please try again or{' '}
    <ContactUsLink />.
  </>
);
export const AccountBlockedErrorPasswordless: FC = () => (
  <>
    Your account has been locked. To unlock your account, please{' '}
    <ContactUsLink />.
  </>
);

export const TemporaryCardAlreadyRegistered = ({
  onClick,
}: {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}) => (
  <>
    This temporary card has been registered, please&nbsp;
    <RebrandButton
      className="inline"
      variant="tertiary"
      fluid
      onClick={onClick}
    >
      sign in
    </RebrandButton>
    &nbsp;to your account.
  </>
);

const passwordGenericError =
  'Sorry, your password is weak and easily guessed by hackers. Please avoid common words or personal information like birthdates or names. Ensure that your password contains a mix of uppercase and lowercase letters, numbers, special characters and at least 8 characters long';
const passwordNoUserInfoError =
  'Sorry, your password is weak and easily guessed by hackers. Please use a stronger password with a mix of upper and lowercase letters, numbers, and avoid common words or personal information like birthdates or names.';
const errorDictionaryMessages = (
  setMode: (newMode: Mode) => void,
  setIsNextStep?: React.Dispatch<React.SetStateAction<boolean>>
) => ({
  signIn: {
    invalid_user_password: (
      <div>
        The email, member number or password you entered does not match our
        records. Please try again, or&nbsp;
        <Button
          noArrow
          plain
          classNames="inline-flex text-fb-darkBlue"
          onClick={() => setMode('resetPassword')}
          ariaLabel="reset your password"
        >
          reset your password
        </Button>
        .
      </div>
    ),
    too_many_attempts: (
      <>
        Your account has reached the maximum number of failed login attempts and
        has been locked. We sent you an email with a link to unlock it.
      </>
    ),
    password_change_required: (
      <>
        To keep your account secure, we require that you &nbsp;
        <Button
          noArrow
          plain
          classNames="inline-flex text-fb-darkBlue underline"
          onClick={() => setMode('resetPassword')}
          ariaLabel="reset your password"
        >
          reset your password
        </Button>
        . Please avoid common words or personal information like birthdates or
        names. Ensure that your password contains a mix of uppercase and
        lowercase letters, numbers, and special characters.
      </>
    ),
    unauthorized: (
      <>
        Your account has been locked. To unlock your account, please{' '}
        <ContactUsLink />.
      </>
    ),
    invalid_captcha: <>Invalid captcha value, please retry</>,
    'passwordless_error-primaryIdentity-not-found': (
      <>
        We&apos;ve had an error processing this email. Please try again or use a
        different email.
      </>
    ),
  },
  signUp: {
    invalid_password: passwordGenericError,
    password_dictionary_error: passwordGenericError,
    password_no_user_info_error: passwordNoUserInfoError,
    password_strength_error: passwordGenericError,
    invalid_signup:
      'The email you entered is invalid. Please try again or use a different email.', // Auth0 uses this code to improve security against a potential username enumeration attack.

    invalid_card_no:
      'The temporary card or registration number you entered is invalid. Please double-check the number and try again. If you still have issues, contact us for help.',
    already_registered: (
      <TemporaryCardAlreadyRegistered
        onClick={() => {
          setMode('signIn');
        }}
      />
    ),
    unauthorized: (
      <>
        Sorry, you&apos;re not authorised to sign up for Flybuys, possibly due
        to a policy violation. If you need help or believe there&apos;s an
        error, please <ContactUsLink />.
      </>
    ),
    invalid_invitation_link: (
      <>
        There was an error processing your request. Please check your invite
        link with your inviter or&nbsp;
        <ContactUsLink />.
      </>
    ),
    invalid_captcha: <>Invalid captcha value, please retry</>,
    password_leaked: (
      <>
        The password you&apos;ve chosen has previously appeared in a data breach
        of another site which puts this account at high risk of compromise.{' '}
        <Button
          noArrow
          plain
          classNames="inline-flex text-fb-darkBlue underline"
          onClick={() => {
            if (setIsNextStep) {
              setIsNextStep(false);
            }
          }}
          ariaLabel="Go back"
        >
          Go back
        </Button>{' '}
        to update your password.
      </>
    ),
  },
  resetPassword: {
    invalid_password: passwordGenericError,
    password_dictionary_error: passwordGenericError,
    password_no_user_info_error: passwordNoUserInfoError,
    password_strength_error: passwordGenericError,
  },
});

const errorSystemErrorMessages = {
  request_error: <GenericError />,
};

const genericError = <GenericError />;

/**
 * Checks if error is of anomaly type,
 * which happens when bot challenge fails
 * because of an invalid login state
 * @param err
 * @return {boolean}
 */
const isAnomalyError = (err: Auth0Error): boolean =>
  (typeof err !== 'string' &&
    err?.code === 'access_denied' &&
    err?.description === 'Invalid state' &&
    err?.name === 'AnomalyDetected') ??
  false;

/**
 * Gets dictionary message from error type or code. Fallsback
 * to error description and generic message
 * @param error
 */
const getErrorMessage = (
  error: Auth0Error,
  mode: Mode,
  setMode: (newMode: Mode) => void,
  setIsNextStep?: React.Dispatch<React.SetStateAction<boolean>>
): ReactNode => {
  if (typeof error.code === 'string') {
    if (error.code in errorDictionaryMessages(setMode, setIsNextStep)[mode]) {
      let { code } = error;
      if (code && code === 'password_change_required' && mode === 'signIn') {
        code = 'invalid_user_password';
      }
      if (
        code &&
        error.name &&
        code === 'invalid_password' &&
        error.name === 'PasswordNoUserInfoError'
      ) {
        code = 'password_no_user_info_error';
      }
      return errorDictionaryMessages(setMode, setIsNextStep)[mode][code];
    }
    if (error.code in errorSystemErrorMessages) {
      const code = 'invalid_user_password';
      return errorDictionaryMessages(setMode)[mode][code];
    }
  }

  if (
    typeof error.description === 'string' &&
    error.description in errorDictionaryMessages(setMode, setIsNextStep)[mode]
  ) {
    return errorDictionaryMessages(setMode, setIsNextStep)[mode][
      error.description
    ];
  }
  const code = 'invalid_user_password';
  return errorDictionaryMessages(setMode)[mode][code];
};

/**
 * Redirects back to referrer, to solve
 * anomaly state, if redirect_uri param is
 * available
 * @return {boolean}
 */
const redirectToReferrer = (): boolean => {
  try {
    const { redirect_uri: redirectUri } = Object.fromEntries(
      new URLSearchParams(window.location.search).entries()
    );

    if (redirectUri) {
      const url = new URL(redirectUri);

      window.location.href = url.origin;

      return true;
    }
  } catch {
    // parsing error, can't redirect
  }

  return false;
};

const otpNetworkErrors = {
  incorrectCode: 'Wrong email or verification code',
  otpExpired: 'The verification code has expired',
  maxAttemptsReached: 'reached the maximum number of attempts',
  primaryIdentityNotFound: 'passwordless_error-primaryIdentity-not-found',
  userBlocked: 'user is blocked',
  primaryUserBlocked: 'primaryUserBlocked',
};

const otpUIErros = {
  incorrectCode:
    'The verification code is invalid. Please check the code and try again or resend code.',
  maxAttemptsReached: 'Too many failed attempts, please try again later',
  primaryIdentityNotFound: `We've had an error processing this email. Please try again or use a different email.`,
  rateLimitReached: `You've reached the maximum one-time code requests for this session. Please try again in an hour.`,
};

export {
  isAnomalyError,
  getErrorMessage,
  redirectToReferrer,
  otpNetworkErrors,
  otpUIErros,
};
