const addSpaceToString = (input: string) => {
  const output = input.match(/.{1,4}/g);
  return output ? output.join(' ') : '';
};

export const getRegParams = () => {
  const { membership_num: memNoExt = '', registration_num: regNoExt = '' } =
    window.auth0Config?.internalOptions || {};
  return {
    memNoExt: addSpaceToString(memNoExt),
    regNoExt: addSpaceToString(regNoExt),
  };
};
