import { validators, forms } from '@flybuys/utils';
import { AnalyticsV2 } from '@flybuys/analytics';
import { getClientId } from 'helpers/login';
import { frontmatter } from 'metadata/contents';

const { PASSWORD_ERROR_TYPES } = forms;

// SIGNIN

export const validateMemberNoWithNoSpaces = value =>
  /^\d{16}$/.test(value.replace(/\s+/g, ''))
    ? undefined
    : 'Member number must be 16 digits.';

export const validatePassword = value => {
  if (!value) {
    return frontmatter.form.password.error.required;
  }
  return undefined;
};

export const validateContactDomain = value => {
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.contact$/i;
  if (!regex.test(value)) {
    return 'Please enter a valid email address.';
  }
  return undefined;
};

export const validateOtp = input => {
  const numberRegex = /^\d+$/;
  if (!input) return 'One-time code is required';
  if (!numberRegex.test(input)) return 'Please enter a valid one-time code.';
  return null;
};

export const validateUsername = value => {
  if (!value) {
    return frontmatter.form.username.error.required;
  }
  if (
    validators.validateEmail(value) &&
    validateMemberNoWithNoSpaces(value) &&
    validateContactDomain(value)
  ) {
    return frontmatter.form.username.error.invalid;
  }
  return undefined;
};

export const validateUsernameForOtp = value => {
  if (!value) {
    return 'Email is required.';
  }
  if (validators.validateEmail(value)) {
    return 'Please enter a valid email address.';
  }
  return undefined;
};

export const validateSignInForm = ({
  username,
  setUsernameError,
  password,
  setFormError,
}) => {
  const usernameErr = validateUsername(username);
  const passwordErr = validatePassword(password);
  if (usernameErr || passwordErr) {
    setUsernameError(usernameErr);
    setFormError(passwordErr);
    return false;
  }
  return true;
};

export const validateSignInUsername = ({ username, setUsernameError }) => {
  const usernameErr =
    validateContactDomain(username) && validateUsernameForOtp(username);
  if (usernameErr) {
    setUsernameError(usernameErr);
    return false;
  }
  return true;
};

export const validateOtpInput = ({ otpInput, setOtpError }) => {
  const otpInputError = validateOtp(otpInput);
  if (otpInputError) {
    setOtpError(otpInputError);
    return false;
  }
  return true;
};

export const handleUsernameBlur =
  ({
    formatter,
    ref,
    validator,
    setUsername,
    setUsernameError,
    setUsernameOrEmail,
  }) =>
  e => {
    const { value } = e.target;
    const { relatedTarget } = e;
    if (
      relatedTarget &&
      (relatedTarget.id === 'socialPasswordlessBtnContainer' ||
        (relatedTarget.type === 'button' &&
          relatedTarget.innerText &&
          (relatedTarget.innerText.includes('Sign in without a password') ||
            relatedTarget.innerText.includes('Continue with Google') ||
            relatedTarget.innerText.includes('Continue with Apple'))))
    )
      return;
    const error = validator(value);
    setUsernameError(error);
    if (!error) {
      const formattedUsername = formatter(value);
      setUsername(formattedUsername);
      if (setUsernameOrEmail) {
        setUsernameOrEmail(formattedUsername);
      }
      if (ref.current) {
        // eslint-disable-next-line no-param-reassign
        ref.current.value = formattedUsername;
      }
    }
  };

export const handleOtpInputBlur =
  ({ setOtpInput, setOtpError }) =>
  e => {
    const { value } = e.target;
    const error = validateOtp(value);
    setOtpError(error);
    if (!error) {
      setOtpInput(value);
    }
  };
// SIGNUP

export const validateStepOneForm = ({
  email,
  setEmailError,
  password,
  setPasswordErrors,
  setTouched,
}) => {
  const emailErr =
    validators.validateEmail(email) && validateContactDomain(email);
  const charsError = validators.validatePasswordChars(password);
  const numberError = validators.validatePasswordNumber(password);
  const upperLowerError = validators.validatePasswordUpperLower(password);
  const specialCharacters = validators.validatePasswordSpecialChars(password);
  const noSpaceError = validators.validatePasswordWhiteSpace(password);
  if (
    emailErr ||
    charsError !== undefined ||
    numberError !== undefined ||
    upperLowerError !== undefined ||
    specialCharacters !== undefined ||
    noSpaceError !== undefined
  ) {
    setEmailError(emailErr);
    setTouched(true);
    setPasswordErrors({
      [PASSWORD_ERROR_TYPES.CHARS]: charsError,
      [PASSWORD_ERROR_TYPES.NUMBER]: numberError,
      [PASSWORD_ERROR_TYPES.UPPER_LOWER]: upperLowerError,
      [PASSWORD_ERROR_TYPES.SPECIAL_CHARS]: specialCharacters,
      [PASSWORD_ERROR_TYPES.NO_SPACE]: noSpaceError,
    });
    return false;
  }
  return true;
};

export const validateStepTwoForm = ({
  firstname,
  lastname,
  dob,
  memberNo,
  regoNo,
  tnc,
  setFirstnameError,
  setLastnameError,
  setDobError,
  setMemberNoError,
  setRegoNoError,
  setTncError,
}) => {
  let memberNoError;
  let regoNoError;
  const firstnameErr = validators.validateFirstname(
    frontmatter.joinForm.firstname.label,
    firstname
  );
  const lastnameErr = validators.validateName(
    frontmatter.joinForm.lastname.label,
    lastname
  );
  const dobError = validators.validateDOB(dob);
  if (memberNo || regoNo) {
    memberNoError = validators.validateMemberNum(memberNo);
    regoNoError = validators.validateRegoNum(regoNo);
  }
  const tncError = !tnc ? frontmatter.joinForm.tnc.error.required : '';

  if (
    firstnameErr ||
    lastnameErr ||
    dobError ||
    memberNoError ||
    regoNoError ||
    tncError
  ) {
    setFirstnameError(firstnameErr);
    setLastnameError(lastnameErr);
    setDobError(dobError);
    setMemberNoError(memberNoError);
    setRegoNoError(regoNoError);
    setTncError(tncError);
    return false;
  }
  return true;
};

export const validateContinueSignUpForm = form => {
  return validateStepOneForm(form);
};

export const validateSignUpForm = form => {
  return validateStepOneForm(form) && validateStepTwoForm(form);
};

export const handleChange =
  ({ setValue }) =>
  ({ target: { value } }) => {
    if (setValue) {
      setValue(value.trim());
    }
  };

export const handleEmailBlur = (validator, setError) => event => {
  const {
    target: { value },
    relatedTarget,
  } = event;
  if (
    relatedTarget &&
    (relatedTarget.id === 'alreadyMemberSignInContainer' ||
      (relatedTarget.type === 'button' &&
        relatedTarget.innerText.includes(
          frontmatter.joinForm.cta.secondary.label
        )))
  )
    return;
  const error = validator(value) && validateContactDomain(value);
  setError(error);
};

export const handleBlur =
  (label, validator, setError) =>
  ({ target: { value } }) => {
    setError(validator(label, value.trim()));
  };

export const handlePasswordBlur =
  (validator, setError) =>
  ({ target: { value } }) => {
    setError(validator(value.trim()));
  };

export const handleCardBlur =
  (validator, setError) =>
  ({ target: { value } }) => {
    setError(validator(value));
  };

export const handleDOBChange = form => value => {
  form.setDob(value);
};

export const handleCheckBoxChange = form => () => {
  form.setTnc(prevState => {
    return !prevState;
  });
  form.setTncError('');
};

export const handleDOBBlur = (form, validator) => value => {
  form.setDobError(validator(value));
};

export const submitSignIn = (form, login, cta, partnerClient, isUnlinking) => {
  if (validateSignInForm(form)) {
    const successCallback = () => {
      AnalyticsV2.trackEvent(
        // eslint-disable-next-line no-nested-ternary
        partnerClient
          ? isUnlinking
            ? 'PartnerUnlinking_UnLinkNow'
            : 'PartnerLinking_Continue'
          : 'Login_SignIn',
        {
          pageInfo: {
            clientID: getClientId(),
            pageName: 'login',
            pagePath: '/',
            siteSection: 'login',
          },
          buttonInfo: {
            buttonName: cta.label,
          },
        }
      );
    };

    login(form, successCallback);
  }
};

export const submitSignUp = (form, signUp, ctaLabel) => {
  if (validateSignUpForm(form)) {
    AnalyticsV2.trackEvent('Registration_TempCardDropdown', {
      pageInfo: {
        clientID: getClientId(),
        temporaryCard: !!form.memberNo && !!form.regoNo,
      },
    });
    const successCallback = () =>
      AnalyticsV2.trackEvent('Registration_JoinFlybuys', {
        pageInfo: {
          clientID: getClientId(),
          pageName: 'registration',
          pagePath: '/mf',
          siteSection: 'registration',
        },
        buttonInfo: {
          buttonName: ctaLabel,
          buttonLink: '',
        },
      });

    signUp(form, successCallback);
  }
};

export const continueSignUp = (form, setIsNextStep, ctalabel) => {
  if (validateContinueSignUpForm(form)) {
    try {
      AnalyticsV2.trackEvent('Registration_Step1Continue', {
        pageInfo: {
          clientID: getClientId(),
          pageName: 'registration',
          pagePath: '/registration',
          siteSection: 'registration',
        },
        buttonInfo: {
          buttonName: ctalabel,
          buttonLink: '',
        },
      });
    } finally {
      setIsNextStep(true);
    }
  }
};

export const createPasswordGetErrors = ({ passwordErrors }) =>
  passwordErrors[PASSWORD_ERROR_TYPES.CHARS] ||
  passwordErrors[PASSWORD_ERROR_TYPES.NUMBER] ||
  passwordErrors[PASSWORD_ERROR_TYPES.UPPER_LOWER] ||
  passwordErrors[PASSWORD_ERROR_TYPES.SPECIAL_CHARS];
