import React from 'react';
import { Helmet } from 'react-helmet';
import { HostTypes, configs } from '@flybuys/utils';
import { renderAdobeScript } from '@flybuys/analytics';

const { getHost } = configs;

export default function MetaPage({
  pageTitle,
  pageDescription,
}: {
  pageTitle?: string;
  pageDescription?: string;
}) {
  const isFbApp = localStorage.getItem('isFbApp') === 'true';
  return (
    <Helmet>
      <html lang="en" />
      <title>{pageTitle || 'Flybuys Login'}</title>
      <meta
        name="description"
        content={
          pageDescription ||
          `Find out why we're Australia's most popular rewards program. Get money off your Coles shop or redeem from over 1,000 rewards. Start collecting today.`
        }
      />
      {!isFbApp && (
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${getHost(
            HostTypes.MAIN_HOST
          )}/flybuys-web/static/flybuys-static-heisenberg/images/root/apple-touch-icon.png`}
        />
      )}
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={`${getHost(
          HostTypes.MAIN_HOST
        )}/flybuys-web/static/flybuys-static-heisenberg/images/root/favicon-32x32.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={`${getHost(
          HostTypes.MAIN_HOST
        )}/flybuys-web/static/flybuys-static-heisenberg/images/root/favicon-16x16.png`}
      />
      <link
        rel="mask-icon"
        href={`${getHost(
          HostTypes.MAIN_HOST
        )}/flybuys-web/static/flybuys-static-heisenberg/images/root/safari-pinned-tab.svg`}
        color="#0058A4"
      />
      <meta
        name="msapplication-config"
        content={`${getHost(
          HostTypes.MAIN_HOST
        )}/flybuys-web/static/flybuys-static-heisenberg/images/root/browserconfig.xml`}
      />
      {!isFbApp && (
        <meta
          name="apple-itunes-app"
          content="app-id=377842707, app-argument=https://experience.flybuys.com.au/"
        />
      )}
      {renderAdobeScript(process.env.APP_ENV)}
    </Helmet>
  );
}
