"use client";

// src/base/table-body.tsx
import { TableBody as TableBodyBase } from "@react-stately/table";
var TableBody = TableBodyBase;
var table_body_default = TableBody;

export {
  table_body_default
};
