import { SigninContext } from 'providers/SigninProvider';
import React, { useContext } from 'react';
import { PasswordRecovery } from './PasswordRecovery';
import { SignIn } from './SignIn';
import { SigninOtp } from './SigninOtp';
import { SignInPage } from './SignInPage';

const SigninContainer = () => {
  const { screen } = useContext(SigninContext);
  let container = <SignIn />;
  if (screen === 'withEmail') {
    container = <SignInPage />;
  }
  if (screen === 'withOtp') {
    container = <SigninOtp />;
  }
  if (screen === 'resetPassword') {
    container = <PasswordRecovery />;
  }
  return container;
};
export default SigninContainer;
