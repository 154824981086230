import { partners, Partner } from 'metadata/partners';
import { getHost } from 'helpers/urls';
import { HostTypes } from '@flybuys/utils';

const PARTNERS_PREFIX = 'partner-';

export const getClientId = () => window.auth0Config?.clientID;

export const getPartnerMetadataByClientId = (clientId: string) =>
  partners.find((partner: Partner) => clientId.match(partner.auth0ClientId));

export const getPartnerClient = () => {
  const clientId = getClientId();
  const isPartner = clientId?.startsWith(PARTNERS_PREFIX);
  return isPartner ? getPartnerMetadataByClientId(clientId) : null;
};

export const getJoinLink = (partnerClient?: Partner | null) => {
  const url = new URL(`${getHost(HostTypes.MY_ACCOUNT)}/join`);
  if (partnerClient) {
    url.searchParams.append(
      'partnerJoinLink',
      encodeURIComponent(`${window.location.href}&login_action=register`)
    );
  }
  return url.href;
};

export const handleBlockedUser = (cb: () => void) => {
  const { user_blocked: userBlocked } =
    window.auth0Config?.internalOptions || {};
  if (userBlocked) {
    cb();
  }
};

export const handleSocialLinkingError = (cb: (err: string) => void) => {
  const { social_linking_error: socialLinkingError } =
    window.auth0Config?.internalOptions || {};
  if (socialLinkingError) {
    cb(socialLinkingError);
  }
};

export const handlePasswordlessLinkingError = (cb: (err: string) => void) => {
  const { passwordless_linking_error: passwordlessLinkingError } =
    window.auth0Config?.internalOptions || {};
  if (passwordlessLinkingError) {
    cb(passwordlessLinkingError);
  }
};
