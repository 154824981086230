import { AnalyticsV2 } from '@flybuys/analytics';
import { getClientId } from 'helpers/login';

export const socialEvents = {
  signInWithGoogleBtnClicked: () => {
    AnalyticsV2.trackEvent('Login_Method', {
      pageInfo: {
        clientID: getClientId(),
        pageName: 'login',
        pagePath: '/',
        siteSection: 'login',
      },
      buttonInfo: {
        buttonName: 'Continue with Google',
        buttonLink: '',
      },
      authInfo: {
        loginType: 'Google Account',
      },
    });
  },
  signInWithAppleBtnClicked: () => {
    AnalyticsV2.trackEvent('Login_Method', {
      pageInfo: {
        clientID: getClientId(),
        pageName: 'login',
        pagePath: '/',
        siteSection: 'login',
      },
      buttonInfo: {
        buttonName: 'Continue with Apple',
        buttonLink: '',
      },
      authInfo: {
        loginType: 'Apple Account',
      },
    });
  },
};
