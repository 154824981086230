import React, {
  createContext,
  FC,
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import type {
  Auth0Error,
  AuthorizeOptions,
  DbSignUpOptions,
  LoginOptions,
  WebAuth,
} from 'auth0-js';
import { AnalyticsV2 } from '@flybuys/analytics';
import { getHost } from 'helpers/urls';
import { HostTypes } from '@flybuys/utils';
import {
  AccountBlockedError,
  getErrorMessage,
  isAnomalyError,
  otpNetworkErrors,
  otpUIErros,
  redirectToReferrer,
} from '../helpers/errorUtils';
import {
  createAuthClient,
  Mode,
  getLoginAction,
} from './WebAuthProvider.helpers';
import { events } from '../adobe/otp_events';

type Provider = 'google-oauth2' | 'apple';
type Login = (
  attrs: { username: string; password: string },
  successCallback?: () => void
) => void;
type LoginWithSocial = (provider: Provider) => void;
type ChangePassword = (email: string, cb: (err?) => void) => void;
type PasswordlessStart = (
  email: string,
  cb?: (error: Auth0Error | null) => void
) => void;
type PasswordlessLogin = (
  email: string,
  code: string,
  cb?: (error: Auth0Error | null) => void
) => void;
type SignUp = (
  attrs: {
    email: string;
    password: string;
    firstname: string;
    lastname: string;
    dob: string;
    memberNo: string;
    regoNo: string;
  },
  successCallback: () => void
) => void;
export type Error = {
  message: ReactNode;
  secondaryMessage?: ReactNode;
};

type WebAuthValue = {
  webAuth: WebAuth | undefined;
  login: Login;
  loginWithSocial: LoginWithSocial;
  signUp: SignUp;
  isBusy: boolean;
  changePassword: ChangePassword;
  authError: Error | undefined;
  setAuthError: React.Dispatch<React.SetStateAction<Error | undefined>>;
  otpError: string;
  setOtpError: React.Dispatch<React.SetStateAction<string>>;
  mode: Mode;
  setMode: (mode: Mode) => void;
  isNextStep: boolean;
  setIsNextStep: React.Dispatch<React.SetStateAction<boolean>>;
  startPasswordless: PasswordlessStart;
  passwordlessLogin: PasswordlessLogin;
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const WebAuthContext = createContext<WebAuthValue>(null);
const useWebAuth = (): WebAuthValue => useContext(WebAuthContext);

const getInitialMode = (): Mode => {
  let mode: Mode = 'signIn';
  const hasRegisterLoginAction = /[&?]login_action=register/iu.test(
    window.location.search
  );
  const isPasswordReset = /[&?]password_reset=true/iu.test(
    window.location.search
  );
  if (hasRegisterLoginAction) {
    mode = 'signUp';
  }
  if (isPasswordReset) {
    mode = 'resetPassword';
  }
  return mode;
};

const WebAuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [webAuth, setWebAuth] = useState<WebAuth>();
  const [authError, setAuthError] = useState<Error>();
  const [otpError, setOtpError] = useState<string>('');
  const [isBusy, setBusy] = useState(false);
  const [isNextStep, setIsNextStep] = React.useState(false);
  const [mode, setModeDispatch] = useState<Mode>(getInitialMode());
  const setMode = (newMode: Mode): void => {
    setBusy(false);
    setAuthError(undefined);
    setModeDispatch(newMode);
  };

  const handleError = (err: Auth0Error): void => {
    if (isAnomalyError(err) && redirectToReferrer()) {
      return;
    }
    setAuthError({
      message: getErrorMessage(err, mode, setMode, setIsNextStep),
    });

    setBusy(false);
    window.scrollTo(0, 0);
  };

  const handleOtpError = (err: Auth0Error): void => {
    if (err.statusCode === 429) {
      setOtpError(otpUIErros.rateLimitReached);
      return;
    }
    if (err.code === 'invalid_user_password' && err.description) {
      if (
        err.description.indexOf(otpNetworkErrors.incorrectCode) >= 0 ||
        err.description.indexOf(otpNetworkErrors.otpExpired) >= 0
      ) {
        setOtpError(otpUIErros.incorrectCode);
      } else if (
        err.description.indexOf(otpNetworkErrors.maxAttemptsReached) >= 0
      ) {
        setOtpError(otpUIErros.maxAttemptsReached);
      }
      return;
    }
    if (err.code === 'extensibility_error' && err.description) {
      if (
        err.description.indexOf(otpNetworkErrors.primaryIdentityNotFound) >= 0
      ) {
        setOtpError(otpUIErros.primaryIdentityNotFound);
      }
      if (err.description.indexOf(otpNetworkErrors.primaryUserBlocked) >= 0) {
        setAuthError({ message: <AccountBlockedError /> });
      }
      return;
    }
    if (
      err.code === 'server_error' &&
      err.description &&
      err.description.indexOf(otpNetworkErrors.userBlocked) >= 0
    ) {
      setAuthError({ message: <AccountBlockedError /> });
      return;
    }
    handleError(err);
  };

  useEffect(() => {
    if (!webAuth) {
      setBusy(true);

      try {
        setWebAuth(createAuthClient());
        setBusy(false);
      } catch (err) {
        window.location.assign(`${getHost(HostTypes.EXPERIENCE)}/error`);
      }
    }
  }, [webAuth]);

  const login: Login = ({ username, password }, successCallback) => {
    setBusy(true);
    setAuthError(undefined);
    return webAuth?.login(
      {
        realm: window.auth0Config?.connection,
        username: username.replace(/\s+/g, ''),
        password,
        login_action: getLoginAction(mode),
        onRedirecting: (done: () => void) => {
          successCallback?.();
          done();
        },
      } as LoginOptions,
      err => {
        if (err) {
          handleError(err);
        } else {
          setBusy(false);
        }
      }
    );
  };

  const loginWithSocial = (provider: Provider) => {
    const authorizeOptions: AuthorizeOptions = {
      connection: provider,
    };
    setAuthError(undefined);
    webAuth?.authorize(authorizeOptions);
  };

  const startPasswordless: PasswordlessStart = (email, cb) => {
    setAuthError(undefined);
    setBusy(true);
    webAuth?.passwordlessStart(
      {
        connection: 'email',
        send: 'code',
        email,
      },
      function (err) {
        setBusy(false);
        if (err) {
          handleOtpError(err);
          if (cb) {
            cb(err);
          }
          return;
        }
        if (cb) {
          cb(null);
        }
      }
    );
  };

  const passwordlessLogin: PasswordlessLogin = (email, code) => {
    setAuthError(undefined);
    setBusy(true);
    webAuth?.passwordlessLogin(
      {
        connection: 'email',
        email,
        verificationCode: code,
        onRedirecting: (done: () => void) => {
          events.signInWithOtpSuccess();
          done();
        },
      } as any,
      function (err) {
        setBusy(false);
        if (err) {
          handleOtpError(err);
        }
      }
    );
  };

  const signUp: SignUp = (
    { email, password, firstname, lastname, dob, memberNo, regoNo },
    successCallback
  ) => {
    setBusy(true);
    setAuthError(undefined);
    return webAuth?.signup(
      {
        connection: window.auth0Config?.connection,
        email,
        password,
        given_name: firstname,
        family_name: lastname,
        username: uuidv4(),
        login_action: getLoginAction(mode),
        user_metadata: {
          dob,
          memberNo: memberNo.replace(/\s+/g, ''),
          regoNo: regoNo.replace(/\s+/g, ''),
          tag: window.auth0Config?.internalOptions.tag,
        },
      } as DbSignUpOptions,
      err => {
        if (err) {
          if (memberNo && regoNo && err.description === 'invalid_card_no') {
            AnalyticsV2.trackEvent('Registration_TempCardAccepted', {
              pageInfo: {
                temporaryCardAccepted: false,
              },
            });
          }
          handleError(err);
        } else {
          successCallback();
          if (memberNo && regoNo) {
            AnalyticsV2.trackEvent('Registration_TempCardAccepted', {
              pageInfo: {
                temporaryCardAccepted: true,
              },
            });
          }

          login({
            username: email,
            password,
          });
        }
      }
    );
  };

  const changePassword: ChangePassword = (email, cb) => {
    setBusy(true);
    return webAuth?.changePassword(
      {
        connection: window.auth0Config?.connection,
        email,
      },
      err => {
        if (err) {
          cb(err);
          handleError(err);
          setBusy(false);
        } else {
          cb();
          setBusy(false);
        }
      }
    );
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value: WebAuthValue = {
    webAuth,
    login,
    loginWithSocial,
    signUp,
    isBusy,
    authError,
    mode,
    changePassword,
    setMode,
    setAuthError,
    otpError,
    isNextStep,
    setIsNextStep,
    setOtpError,
    startPasswordless,
    passwordlessLogin,
  };

  return (
    <WebAuthContext.Provider value={value}>{children}</WebAuthContext.Provider>
  );
};

export { WebAuthProvider, useWebAuth };
