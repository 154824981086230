"use client";

// src/base/listbox-section-base.tsx
import { BaseSection } from "@nextui-org/aria-utils";
var ListboxSectionBase = BaseSection;
var listbox_section_base_default = ListboxSectionBase;

export {
  listbox_section_base_default
};
