import React, { useEffect, useState } from 'react';
import { Animation } from '@flybuys/ui-kit';
import MultifactorAuth from '../components/MultifactorAuth';
import MultifactorAuthVerify from '../components/MultifactorAuthVerify/MutifactorAuthVerify';
import { EnrollmentErrors, errors } from '../metadata/errors';
import { initializeMFA } from '../helpers/multifactor.helper';

const mfaRuntimeState: any = {
  allowRememberBrowser: false,
};
export const Multifactor = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [showMultifactorAuth, setShowMultifactorAuth] = useState(false);
  const [invalidOtp, setInvalidOtp] = useState(false);
  const [tooManySms, setTooManySms] = useState(false);
  const [verificationCodeRequired, setVerificationCodeRequired] =
    useState(false);
  const [showMultifactorAuthVerify, setShowMultifactorAuthVerify] =
    useState(false);
  const [allowRememberBrowser, setAllowRememberBrowser] = useState(false);
  const [phoneNumberVal, setPhoneNumberVal] = useState('');
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  const showError = err => {
    let message = EnrollmentErrors.default;
    if (errors.local.indexOf(err.errorCode) !== -1) {
      if (err.errorCode === 'invalid_otp') {
        setInvalidOtp(true);
      }
      if (err.errorCode === 'too_many_sms') {
        setTooManySms(true);
        setError(undefined);
        return;
      }
      message = err.message;
    }
    setError(message);
  };

  const showEnrollmentError = (message: string) => {
    setError(message);
  };

  useEffect(() => {
    initializeMFA(
      setPageLoading,
      setShowMultifactorAuth,
      setShowMultifactorAuthVerify,
      setLoading,
      setIsEnrolled,
      showError,
      showEnrollmentError,
      setPhoneNumberVal,
      mfaRuntimeState
    );
  }, []);

  const onSendVerificationCodeHandler = (phoneNumber: string) => {
    if (loading) return;
    setLoading(true);
    const { transaction } = mfaRuntimeState;
    const enrollData = { phoneNumber: `+61${phoneNumber.substring(1)}` };
    setPhoneNumberVal(phoneNumber);
    transaction.enroll('sms', enrollData, (err, otpEnrollment) => {
      setLoading(false);
      if (err) {
        showError(err);
        return;
      }
      setShowMultifactorAuth(false);
      setShowMultifactorAuthVerify(true);
      mfaRuntimeState.otpEnrollment = otpEnrollment;
    });
  };

  const verifyEnrollment = (code: string) => {
    setInvalidOtp(false);
    setError(undefined);
    if (code.length === 0) {
      setVerificationCodeRequired(true);
      return;
    }
    if (loading) return;
    setLoading(true);
    if (mfaRuntimeState.authFlow) {
      mfaRuntimeState.authFlow.verify({ otpCode: code });
      return;
    }
    const confirmData = { otpCode: code };
    mfaRuntimeState.otpEnrollment.confirm(confirmData, (err, data) => {});
  };

  const editBtnClickedHandler = () => {
    setShowMultifactorAuth(true);
    setShowMultifactorAuthVerify(false);
    setError(undefined);
  };

  const resendSmsHandler = () => {
    setLoading(true);
    const { transaction } = mfaRuntimeState;
    if (transaction.isEnrolled()) {
      const enrollments = transaction.getEnrollments();
      transaction.requestAuth(
        enrollments[0],
        {
          method: enrollments[0].getAvailableMethods()[0],
        },
        (responseErr, authFlow) => {
          if (responseErr) {
            showError(responseErr);
            return;
          }
          mfaRuntimeState.authFlow = authFlow;
        }
      );
      setLoading(false);
      return;
    }
    const enrollData = { phoneNumber: `+61${phoneNumberVal.substring(1)}` };
    transaction.enroll('sms', enrollData, (err, otpEnrollment) => {
      setLoading(false);
      if (err) {
        showError(err);
        return;
      }
      mfaRuntimeState.otpEnrollment = otpEnrollment;
    });
  };

  const handleAllowRememberBrowserUpdate = (val: boolean) => {
    mfaRuntimeState.allowRememberBrowser = val;
    setAllowRememberBrowser(val);
  };

  return pageLoading ? (
    <div className="mt-[40%]">
      <Animation.PageLoading />
    </div>
  ) : (
    <>
      {showMultifactorAuth && (
        <MultifactorAuth
          error={error}
          tooManySms={tooManySms}
          loading={loading}
          onSendVerificationCode={onSendVerificationCodeHandler}
        />
      )}
      {showMultifactorAuthVerify && (
        <MultifactorAuthVerify
          error={error}
          invalidOtp={invalidOtp}
          tooManySms={tooManySms}
          onEditBtnClicked={editBtnClickedHandler}
          onVerifySms={verifyEnrollment}
          onResendSms={resendSmsHandler}
          phoneNumber={phoneNumberVal}
          loading={loading}
          enrolled={isEnrolled}
          verificationCodeRequired={verificationCodeRequired}
          setVerificationCodeRequired={value =>
            setVerificationCodeRequired(value)
          }
          allowRememberBrowser={allowRememberBrowser}
          onAllowRememberBrowserUpdate={handleAllowRememberBrowserUpdate}
        />
      )}
    </>
  );
};
