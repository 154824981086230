import React, { useState } from 'react';
import {
  Button,
  rebrand,
  WhyMobileModal,
  ButtonLink,
  Auth0FormLayout,
} from '@flybuys/ui-kit';
import { mobileModalProps } from 'metadata/modalContent';
import { getHost } from 'helpers/urls';
import { handleMobileNoChange, handleSubmit } from 'helpers/multifactor.helper';
import { TooManySMSError } from 'helpers/errorUtils';
import { HostTypes } from '@flybuys/utils';

const { Form } = rebrand;
const { Header, ErrorMessage, FormLayout } = Auth0FormLayout;

type MultifactorAuthProps = {
  onSendVerificationCode: (mobileNumber: string) => void;
  loading: boolean;
  error: string | undefined;
  tooManySms: boolean;
};

const MultifactorAuth = ({
  loading,
  onSendVerificationCode,
  error,
  tooManySms,
}: MultifactorAuthProps) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>('');
  const [mobileNo, setMobileNo] = useState('');
  const mobileSubmitBtn = React.useRef();
  const onKeyUp = (event: React.KeyboardEvent<HTMLFormElement>): void => {
    handleMobileNoChange(event, mobileNo, setErrorMessage, mobileSubmitBtn);
  };

  const formHeaderProps = {
    heading: 'Enter your mobile number',
    description: (
      <>
        Your mobile number helps us keep your account secure.
        <br />
        See our{' '}
        <ButtonLink
          noArrow
          plain
          href={`${getHost(HostTypes.EXPERIENCE)}/policies/privacy-policy/`}
          target="_blank"
          classNames="inline-flex text-fb-darkBlue underline"
        >
          privacy policy
        </ButtonLink>{' '}
        for more information.
      </>
    ),
    classNames: 'mb-4',
  };

  return (
    <FormLayout>
      {tooManySms && <ErrorMessage message={<TooManySMSError />} />}
      {error && <ErrorMessage message={error} />}
      <Header {...formHeaderProps} />
      <form
        onKeyUp={onKeyUp}
        onSubmit={e => {
          e.preventDefault();
          return false;
        }}
        role="presentation"
      >
        <div className="mt-16">
          <Form.MobileNoField
            value={mobileNo}
            id="mobileInput"
            onChange={event => setMobileNo(event.target.value.trim())}
            onBlur={e =>
              handleMobileNoChange(
                e,
                mobileNo,
                setErrorMessage,
                mobileSubmitBtn
              )
            }
            errorMsg={errorMessage}
            withPadding={false}
            classNames={{ container: 'mt-7' }}
          />
          <WhyMobileModal
            {...mobileModalProps}
            classNames="mt-2 ml-4 mb-12 text-fb-body-xs md:mb-20 why-modal"
          />
        </div>
        <div className="fb-layout-cta">
          <Button
            ref={mobileSubmitBtn}
            noArrow
            loading={loading}
            classNames="mt-12 w-full"
            onClick={() =>
              handleSubmit(
                mobileNo,
                errorMessage,
                setErrorMessage,
                onSendVerificationCode
              )
            }
            ariaLabel="Send verification code"
          >
            Send verification code
          </Button>
        </div>
      </form>
    </FormLayout>
  );
};
export default MultifactorAuth;
