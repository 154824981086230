import React, { FC, useEffect, useRef } from 'react';
import { Button, rebrand, uiHelpers, Auth0FormLayout } from '@flybuys/ui-kit';
import { forms, HostTypes } from '@flybuys/utils';
import { AnalyticsV2 } from '@flybuys/analytics';
import {
  handleChange,
  validatePassword,
  handleUsernameBlur,
  validateUsername,
  submitSignIn,
  handlePasswordBlur,
} from 'helpers/forms';

import {
  getPartnerClient,
  getClientId,
  handleBlockedUser,
  handleSocialLinkingError,
} from 'helpers/login';

import { useWebAuth } from 'providers/WebAuthProvider';
import { frontmatter } from 'metadata/contents';
import {
  AccountBlockedError,
  AppleAccountLinkingError,
  GoogleAccountLinkingError,
  SocialLinkingGenericError,
} from 'helpers/errorUtils';
import { getHost } from 'helpers/urls';
import FlybuysLogo from './FlybuysLogo';
import { useSigninContext } from 'providers/SigninProvider';
import { isCardNumber, isMobileApp } from 'helpers/signin';
import SignInHelp from './modals/SignInHelpModal';
import Separator from './UiHelpers/Separator';
import { events } from '../adobe/otp_events';
import { SocialSignIn } from './SocialSignIn';

const { Form } = rebrand;
const { Header, ErrorMessage, FormLayout } = Auth0FormLayout;

const {
  formatters: { formatMemberNo },
} = uiHelpers;

const { handlePasswordToggle, usePasswordForm } = forms;

const useUsernameForm = () => {
  const [username, setUsername] = React.useState('');
  const [usernameError, setUsernameError] = React.useState('');
  return {
    username,
    setUsername,
    usernameError,
    setUsernameError,
  };
};

export const SignIn: FC = () => {
  const isUnlinking =
    new URLSearchParams(window.location.search).get('login_action') ===
    'delink';
  const { login, isBusy, setMode, authError, setAuthError, webAuth } =
    useWebAuth();
  const { setScreen, usernameOrEmail, setUsernameOrEmail } = useSigninContext();
  const form = { ...useUsernameForm(), ...usePasswordForm() };
  const { setUsername } = form;
  const inputFieldRef = React.useRef();
  const partnerClient = getPartnerClient();
  const { form: metadata } = frontmatter;
  const divContainerRef = useRef(null);
  useEffect(() => {
    if (divContainerRef.current) {
      const divElem = divContainerRef.current as HTMLDivElement;
      divElem.setAttribute('tabindex', '0');
    }
    if (usernameOrEmail) {
      setUsername(usernameOrEmail);
    }
    handleSocialLinkingError(error => {
      if (error === 'appleAccountLinkingError') {
        setAuthError({ message: <AppleAccountLinkingError /> });
      } else if (error === 'googleAccountLinkingError') {
        setAuthError({ message: <GoogleAccountLinkingError /> });
      } else {
        setAuthError({ message: <SocialLinkingGenericError /> });
      }
    });
    handleBlockedUser(() => {
      setAuthError({ message: <AccountBlockedError /> });
    });
  }, [setAuthError, usernameOrEmail, setUsername]);
  const usernameNoProps = {
    id: metadata.username.id,
    name: metadata.username.name,
    label: metadata.username.label,
    value: usernameOrEmail || form.username,
    errorMsg: form.usernameError,
    maxLength: 100,
    onChange: handleChange({
      setValue: value => {
        form.setUsername(value);
        setUsernameOrEmail(value);
      },
    }),
    onBlur: handleUsernameBlur({
      formatter: formatMemberNo,
      ref: inputFieldRef,
      validator: validateUsername,
      setUsername: form.setUsername,
      setUsernameError: form.setUsernameError,
      setUsernameOrEmail,
    }),
    ariaDescribedBy: metadata.username.error.id,
    ref: { inputFieldRef },
    withPadding: true,
  };
  const passwordProps = {
    id: 'default-pass',
    name: metadata.password.name,
    passwordToggle: form.passwordToggle,
    label: metadata.password.label,
    value: form.password,
    maxLength: 20,
    errorMsg: form.formError, // TODO: rename formError to passwordFieldError in usePasswordForm in utils
    onChange: handleChange({
      setValue: form.setPassword,
    }),
    onBlur: handlePasswordBlur(
      validatePassword,
      form.setFormError // TODO: rename setFormError to setPasswordFieldError in usePasswordForm in utils
    ),
    handlePasswordToggle: handlePasswordToggle(form),
    ariaDescribedBy: metadata.password.error.id,
    withPadding: false,
  };

  const formLayoutProps = {
    logo: <FlybuysLogo url={getHost(HostTypes.EXPERIENCE)} />,
    partner: partnerClient
      ? { logoUrl: partnerClient.logo, name: partnerClient.name }
      : null,
  };
  const formHeaderProps = {
    // eslint-disable-next-line no-nested-ternary
    heading: partnerClient
      ? isUnlinking
        ? metadata.unlinkingTitle
        : metadata.linkingTitle
      : metadata.title,
    // eslint-disable-next-line no-nested-ternary
    description: partnerClient
      ? isUnlinking
        ? metadata.unlinkingSubtitle
        : metadata.linkingSubtitle
      : metadata.subtitle,
  };

  const formMessageProps = {
    message: authError?.message,
    secondaryMessage: authError?.secondaryMessage,
  };

  const cta = {
    // eslint-disable-next-line no-nested-ternary
    label: partnerClient
      ? isUnlinking
        ? metadata.cta.primary.unlinkingLabel
        : metadata.cta.primary.linkingLabel
      : metadata.cta.primary.label,
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLFormElement>): void => {
    if (event.key === 'Enter') {
      submitSignIn(form, login, cta, partnerClient, isUnlinking);
    }
  };

  return (
    <FormLayout {...formLayoutProps}>
      {formMessageProps.message && <ErrorMessage {...formMessageProps} />}
      <Header {...formHeaderProps} />
      <div className="mt-16 sm:mt-24">
        <form
          onKeyDown={e => {
            onKeyDown(e);
          }}
          role="presentation"
        >
          <Form.TextField {...usernameNoProps} />
          <Form.PasswordField {...passwordProps} />
        </form>
        <Button
          plain
          onClick={() => {
            AnalyticsV2.trackEvent(
              // eslint-disable-next-line no-nested-ternary
              partnerClient
                ? isUnlinking
                  ? 'PartnerUnlinking_ForgotPasswordLink'
                  : 'PartnerLinking_ForgotPasswordLink'
                : 'Login_ForgotPasswordLink',
              {
                pageInfo: {
                  clientID: getClientId(),
                  pageName: 'login',
                  pagePath: '/',
                  siteSection: 'login',
                },
                buttonInfo: {
                  buttonName: metadata.password.helper,
                  buttonLink: '',
                },
              }
            );
            setMode('resetPassword');
          }}
          classNames="flex ml-4 mt-2 font-public-sans-lt text-fb-darkBlue underline"
          ariaLabel={metadata.password.helper}
        >
          {metadata.password.helper}
        </Button>
        <Button
          loadingWhite={isBusy}
          color="primary"
          id="btn-login"
          dataTestId="sign-in-btn"
          disabled={isBusy}
          classNames="mt-8 sm:mt-12 sm:mb-4 w-full"
          noArrow
          ariaLabel={cta.label}
          onClick={() => {
            submitSignIn(form, login, cta, partnerClient, isUnlinking);
          }}
        >
          {cta.label}
        </Button>
        {!isCardNumber(form.username) && !getPartnerClient() && (
          <div
            id="socialPasswordlessBtnContainer"
            className="flex flex-col"
            ref={divContainerRef}
          >
            <Separator />
            <Button
              id="btn-otp"
              disabled={isBusy}
              secondary
              classNames="w-full mb-4"
              noArrow
              dataTestId="btn-login-otp"
              ariaLabel="Sign in with a one-time code"
              onClick={() => {
                events.signInWithOtpBtnClicked();
                setScreen('withEmail');
              }}
            >
              Sign in without a password
            </Button>
            <SocialSignIn />
          </div>
        )}
        <div className="text-center">
          {!isUnlinking && (
            <Button
              plain
              onClick={() => {
                AnalyticsV2.trackEvent(
                  // eslint-disable-next-line no-nested-ternary
                  partnerClient
                    ? isUnlinking
                      ? 'PartnerUnlinking_NoAccountJoinToday'
                      : 'PartnerLinking_NoAccountJoinToday'
                    : 'Login_NoAccountJoinToday',
                  {
                    pageInfo: {
                      clientID: getClientId(),
                      pageName: 'login',
                      pagePath: '/',
                      siteSection: 'login',
                    },
                    buttonInfo: {
                      buttonName: metadata.cta.secondary.label,
                      buttonLink: '',
                    },
                  }
                );
                setMode('signUp');
              }}
              classNames="block w-full mt-8 text-fb-darkBlue underline"
              ariaLabel={metadata.cta.secondary.label}
            >
              {metadata.cta.secondary.label}
            </Button>
          )}
          <SignInHelp {...{ partnerClient, isUnlinking }} />
        </div>
      </div>
    </FormLayout>
  );
};
