const required = 'is required.';

const defaultLoginError =
  'Incorrect email or password. Please try your flybuys number.';

const memberNumberLoginError = 'Incorrect member number or password.';

const form = {
  username: {
    id: 'username',
    name: 'username',
    label: 'Email or member number',
    rightItem: {
      label: 'Scan card',
    },
    error: {
      id: 'username-error',
      required: `Email or Member number ${required}`,
      invalid: 'Email or Member number is invalid.',
    },
  },
  password: {
    id: 'login-password',
    name: 'password',
    label: 'Password',
    helper: 'Forgot password?',
    error: {
      id: 'login-pass-error',
      required: `Password ${required}`,
    },
  },
  loginError: {
    id: 'login-error',
    email: defaultLoginError,
    memberNo: memberNumberLoginError,
  },
  title: 'Sign in to your account',
  subtitle: '',
  linkingTitle: 'Link your accounts',
  linkingSubtitle: 'Sign in to Flybuys to link your accounts.',
  unlinkingTitle: 'Unlink your accounts',
  unlinkingSubtitle:
    'Enter your details below to sign in to Flybuys and unlink your accounts.',
  cta: {
    primary: {
      label: 'Sign in',
      linkingLabel: 'Continue',
      unlinkingLabel: 'Unlink now',
    },
    secondary: {
      label: "Don't have an account? Join today",
    },
  },
};

const joinForm = {
  email: {
    id: 'email',
    name: 'email',
    label: 'Email',
    rightItem: {
      label: 'Scan card',
    },
    error: {
      id: 'username-error',
    },
  },
  password: {
    id: 'join-password',
    name: 'password',
    label: 'Password',
    helper: 'Forgot password?',
    error: {
      id: 'join-pass-error',
    },
  },
  firstname: {
    id: 'firstname',
    name: 'firstname',
    label: 'First name',
    error: {
      id: 'join-firstname-error',
    },
  },
  lastname: {
    id: 'lastname',
    name: 'lastname',
    label: 'Last name',
    error: {
      id: 'join-lastname-error',
    },
  },
  dob: {
    id: 'dob',
    name: 'dob',
    label: 'Date of birth (dd/mm/yyyy)',
    error: {
      id: 'join-dob-error',
    },
  },
  tempCardMemberNo: {
    id: 'tempCardMemberNo',
    name: 'tempCardMemberNo',
    label: 'Membership number',
    error: {
      id: 'join-tempCardMemberNo-error',
    },
  },
  tempCardRegNo: {
    id: 'tempCardRegNo',
    name: 'tempCardRegNo',
    label: 'Registration number',
    error: {
      id: 'join-tempCardRegNo-error',
    },
  },
  tnc: {
    id: 'tnc',
    name: 'tnc',
    label: 'Registration number',
    error: {
      id: 'join-tnc-error',
      required: 'Please accept the Terms and Conditions to join.',
    },
  },
  joinError: {
    id: 'join-error',
  },
  cta: {
    primary: {
      continue: 'Continue',
      join: 'Join Flybuys',
    },
    secondary: {
      label: 'Already a member? Sign in',
    },
  },
};

const tempCard = {
  lines: ['Have a temporary card already?', 'Join flybuys today'],
};

export const frontmatter = {
  form,
  joinForm,
  tempCard,
};
