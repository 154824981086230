import { AnalyticsV2 } from '@flybuys/analytics';
import { getClientId } from 'helpers/login';

export const events = {
  signInWithOtpBtnClicked: () => {
    AnalyticsV2.trackEvent('Login_Method', {
      pageInfo: {
        clientID: getClientId(),
        pageName: 'login',
        pagePath: '/',
        siteSection: 'login',
      },
      buttonInfo: {
        buttonName: 'Sign in with a one-time code instead',
        buttonLink: '',
      },
      authInfo: {
        loginType: 'One Time Code',
      },
    });
  },
  signInWithPasswordInstead: () => {
    AnalyticsV2.trackEvent('Login_PasswordInstead', {
      pageInfo: {
        clientID: getClientId(),
        pageName: 'login',
        pagePath: '/',
        siteSection: 'login',
      },
      buttonInfo: {
        buttonName: 'Sign in with a password instead',
        buttonLink: '',
      },
    });
  },
  signInWithOtpSuccess: () => {
    AnalyticsV2.trackEvent('Login_SignIn', {
      pageInfo: {
        clientID: getClientId(),
        pageName: 'login',
        pagePath: '/',
        siteSection: 'login',
      },
      buttonInfo: {
        buttonName: 'Sign in: One Time Code',
        buttonLink: '',
      },
      authInfo: {
        loginType: 'One Time Code',
      },
    });
  },
  signInWithOtpContinueBtn: () => {
    AnalyticsV2.trackEvent('Login_Passwordless', {
      pageInfo: {
        clientID: getClientId(),
        pageName: 'login',
        pagePath: '/',
        siteSection: 'login',
      },
      buttonInfo: {
        buttonName: 'Continue - Passwordless',
        buttonLink: '',
      },
      authInfo: {
        loginType: 'One Time Code',
      },
    });
  },
};
