// src/linear/copy.tsx
import { jsx, jsxs } from "react/jsx-runtime";
var CopyLinearIcon = (props) => /* @__PURE__ */ jsxs(
  "svg",
  {
    "aria-hidden": "true",
    fill: "none",
    focusable: "false",
    height: "1em",
    role: "presentation",
    stroke: "currentColor",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "1.5",
    viewBox: "0 0 24 24",
    width: "1em",
    ...props,
    children: [
      /* @__PURE__ */ jsx("path", { d: "M16 17.1c0 3.5-1.4 4.9-4.9 4.9H6.9C3.4 22 2 20.6 2 17.1v-4.2C2 9.4 3.4 8 6.9 8h4.2c3.5 0 4.9 1.4 4.9 4.9Z" }),
      /* @__PURE__ */ jsx("path", { d: "M8 8V6.9C8 3.4 9.4 2 12.9 2h4.2C20.6 2 22 3.4 22 6.9v4.2c0 3.5-1.4 4.9-4.9 4.9H16" }),
      /* @__PURE__ */ jsx("path", { d: "M16 12.9C16 9.4 14.6 8 11.1 8" })
    ]
  }
);

export {
  CopyLinearIcon
};
