import React from 'react';
import { Modal, rebrand } from '@flybuys/ui-kit';
import { AnalyticsV2 } from '@flybuys/analytics';
import { getClientId } from 'helpers/login';
import { Partner } from 'metadata/partners';

const { Heading, BodyText } = rebrand;

type SignInHelpProps = {
  partnerClient?: Partner | null;
  isUnlinking: boolean;
};

export default function SignInHelp({
  partnerClient,
  isUnlinking,
}: SignInHelpProps) {
  const mProps = {
    openLabel: 'Having trouble signing in?',
    contentLabel: '',
    heading: '',
    openerClassNames: 'my-4 text-fb-darkBlue underline',
    appElement: 'body',
    closeOptions: {
      label: 'Ok, got it',
      show: true,
    },
    onOpen: () => {
      AnalyticsV2.trackEvent(
        // eslint-disable-next-line no-nested-ternary
        partnerClient
          ? isUnlinking
            ? 'PartnerUnlinking_HavingTroubleSignIn'
            : 'PartnerLinking_HavingTroubleSignIn'
          : 'Login_HavingTroubleSignIn',
        {
          pageInfo: {
            clientID: getClientId(),
            pageName: 'login',
            pagePath: '/',
            siteSection: 'login',
          },
          buttonInfo: {
            buttonName: 'Having trouble signing in?',
            buttonLink: '',
          },
        }
      );
    },
  };
  return (
    <div className="fb-field-item inline">
      <Modal {...mProps}>
        <Heading as="h3">Having trouble signing in?</Heading>
        <BodyText className="mt-6">
          Trouble signing in with your email? Try your member number.
        </BodyText>
        <BodyText as="small" className="mt-6">
          *Your member number can be found on the back of your Flybuys card.
        </BodyText>
        <img
          className="my-8 w-[220px]"
          alt="Card number"
          src="https://register.flybuys.com.au/cdn/web/img/cardnumber.svg"
        />
      </Modal>
    </div>
  );
}
