import { canUseDOM } from '../query';
import _cancelAnimationFramePolyfill from './cancelAnimationFramePolyfill';
export { _cancelAnimationFramePolyfill as cancelAnimationFramePolyfill };
import _nativeRequestAnimationFrame from './nativeRequestAnimationFrame';
export { _nativeRequestAnimationFrame as nativeRequestAnimationFrame };
import _requestAnimationFramePolyfill from './requestAnimationFramePolyfill';
export { _requestAnimationFramePolyfill as requestAnimationFramePolyfill };
var vendorMap = {
  animation: 'animationend',
  OAnimation: 'oAnimationEnd',
  MozAnimation: 'animationend',
  WebkitAnimation: 'webkitAnimationEnd'
};

function getAnimationEvent() {
  if (!canUseDOM) {
    return;
  }

  var tempAnimationEnd;
  var style = document.createElement('div').style;

  for (tempAnimationEnd in vendorMap) {
    if (style[tempAnimationEnd] !== undefined) {
      return vendorMap[tempAnimationEnd];
    }
  }
}

export var events = function events() {
  return {
    end: getAnimationEvent()
  };
};