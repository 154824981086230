import { Dispatch } from 'react';
import { AnalyticsV2 } from '@flybuys/analytics';
import { PasswordFormContext, apis } from '@flybuys/utils';
import {
  PasswordResetErrorsMessages,
  PasswordResetErrorsName,
} from 'metadata/errors';

export type ResetPasswordErrorResponse = {
  name: string;
  message: string;
  code: string;
  description: string;
  statusCode: number;
};

export const getPasswordResetErrorMessage = (
  error: ResetPasswordErrorResponse
) => {
  const errorName = error.name;
  if (
    Object.values(PasswordResetErrorsName).includes(
      errorName as PasswordResetErrorsName
    ) &&
    error.statusCode === 400
  ) {
    if (
      errorName === PasswordResetErrorsName.PASSWORD_STRENGTH_ERROR ||
      errorName === PasswordResetErrorsName.PASSWORD_HISTORY_ERROR ||
      errorName === PasswordResetErrorsName.PASSWORD_NO_USER_INFO_ERROR
    ) {
      return PasswordResetErrorsMessages[errorName];
    }
    return error.message;
  }
  return 'Network error. Please try again later.';
};

export const handlePasswordResetSubmit = async (
  e: React.FormEvent<HTMLFormElement> | React.FormEvent<HTMLInputElement>,
  form: PasswordFormContext,
  setPasswordSuccess: Dispatch<React.SetStateAction<boolean>>
) => {
  AnalyticsV2.trackEvent('ResetPassword', {
    buttonInfo: {
      buttonName: 'Reset password',
      buttonLink: '',
    },
  });
  const { setLoading, setFormError, password, passwordErrors } = form;
  const { chars, number, upperLower, specialChars } = form.passwordErrors;
  let hasPasswordRulesErrors = false;
  e.preventDefault();
  if (!chars && !number && !upperLower && specialChars) {
    setFormError('special characters allowed are !@#$%^&*');
    return;
  }
  setFormError('');
  if (password.length === 0) {
    form.setFormError('New password is required');
    return;
  }
  Object.keys(form.passwordErrors).forEach(key => {
    if (passwordErrors[key] !== undefined) {
      hasPasswordRulesErrors = true;
      return true;
    }
    return false;
  });
  if (hasPasswordRulesErrors) return;

  setLoading(true);
  const { prConfig } = window;
  const payload = {
    newPassword: password,
    confirmNewPassword: password,
    ...prConfig,
  };
  const res = await apis.POST({ endpoint: '/lo/reset', payload });
  if (apis.isResponseOK(res)) {
    if (!res.data?.success) {
      const url = new URL(res.data.result_url);
      const params = new URLSearchParams(url.search);
      const message = params.get('message');
      if (message) {
        setFormError(message);
      }
      setPasswordSuccess(false);
      setLoading(false);
      return;
    }
    setPasswordSuccess(true);
  } else {
    setPasswordSuccess(false);
    setFormError(getPasswordResetErrorMessage(res.data));
  }
  setLoading(false);
};
