export function compose() {
  for (var _len = arguments.length, funcs = new Array(_len), _key = 0; _key < _len; _key++) {
    funcs[_key] = arguments[_key];
  }

  return funcs.reduce(function (a, b) {
    return function () {
      return a(b.apply(void 0, arguments));
    };
  }, function (arg) {
    return arg;
  });
}
export function setStatic(key, value) {
  return function (BaseComponent) {
    /* eslint-disable no-param-reassign */
    BaseComponent[key] = value;
    /* eslint-enable no-param-reassign */

    return BaseComponent;
  };
}
export function setDisplayName(displayName) {
  return setStatic('displayName', displayName);
}
export function getDisplayName(component) {
  if (typeof component === 'string') {
    return component;
  }

  if (!component) {
    return undefined;
  }

  return component.displayName || component.name || 'Component';
}
export function wrapDisplayName(component, wrapperName) {
  return wrapperName + "(" + getDisplayName(component) + ")";
}
export function setPropTypes(propTypes) {
  return setStatic('propTypes', propTypes);
}