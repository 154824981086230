import { createRef, useEffect } from 'react';

// For autofocusing Form.TextField / Form.MaskedField
const useAutoFocus = inputElRef => {
  const fieldRef = createRef();
  // Can be overwritten by custom ref
  const inputFieldRef = inputElRef ?? createRef();

  useEffect(() => {
    if (inputFieldRef?.current) {
      inputFieldRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { fieldRef, inputFieldRef };
};

export default useAutoFocus;
