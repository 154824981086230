import React from 'react';
import { HostTypes } from '@flybuys/utils';
import FlybuysLogo from 'components/FlybuysLogo';
import { getHost } from 'helpers/urls';
import { Partner } from 'metadata/partners';
import { Error } from 'providers/WebAuthProvider';

export const getSignInFormProps = (
  partnerClient: Partner | null | undefined,
  isUnlinking: boolean,
  metadata: any,
  authError: Error | undefined
) => {
  const formLayoutProps = {
    logo: <FlybuysLogo url={getHost(HostTypes.EXPERIENCE)} />,
    partner: partnerClient
      ? { logoUrl: partnerClient.logo, name: partnerClient.name }
      : null,
  };
  const formHeaderProps = {
    heading: 'Enter your email',
    description: `Enter your email, and we'll send you a one-time code.`,
  };

  const formMessageProps = {
    message: authError?.message,
    secondaryMessage: authError?.secondaryMessage,
  };

  const cta = {
    // eslint-disable-next-line no-nested-ternary
    label: partnerClient
      ? isUnlinking
        ? metadata.cta.primary.unlinkingLabel
        : metadata.cta.primary.linkingLabel
      : metadata.cta.primary.label,
  };
  return { formLayoutProps, formHeaderProps, formMessageProps, cta };
};

export const useUsernameForm = () => {
  const [username, setUsername] = React.useState('');
  const [usernameError, setUsernameError] = React.useState('');
  return {
    username,
    setUsername,
    usernameError,
    setUsernameError,
  };
};

export const useOtpInputForm = () => {
  const [otpInput, setOtpInput] = React.useState('');
  const [otpError, setOtpError] = React.useState('');
  return {
    otpInput,
    setOtpInput,
    otpError,
    setOtpError,
  };
};

export const validateOtpInputMetadata = {
  id: 'validate-otp',
  name: 'validate-otp-input',
  label: 'One-time code',
};

export const resendOtpMetadata = {
  btnText: 'Didn’t receive the code? Resend',
};

export const maskEmail = (email: string) => {
  if (!email) return '';
  return `${email.substring(0, 2)}*******@*******${email.substring(
    email.length - 2,
    email.length
  )}`;
};

export const isCardNumber = (input: string) => {
  if (!input) return false;
  const output = parseInt(input);
  return !Number.isNaN(output);
};

export const getPasswordlessServerError = (error: string) => {
  if (error.indexOf('passwordless_error') >= 0) {
    return `We've had an error processing your request. Please try again later.`;
  }
  return '';
};

export const isMobileApp = () => localStorage.getItem('isFbApp') === 'true';
