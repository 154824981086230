export const initKasada = () => {
  if (process.env.APP_ENV === 'local') return;
  const scriptTagKasada = document.createElement('script');
  const scriptTagKasadaSrc = document.createElement('script');
  scriptTagKasadaSrc.setAttribute(
    'src',
    '/149e9513-01fa-4fb0-aad4-566afd725d1b/2d206a39-8ed7-437e-a3be-862e0f06eea3/p.js'
  );
  scriptTagKasada.type = 'application/javascript';
  scriptTagKasada.text = `
  document.addEventListener('kpsdk-load', function () {
    KPSDK.configure([
      {
        method: 'POST',
        domain: '${window.location.hostname}',
        path: '/usernamepassword/login',
      },
      {
        method: 'POST',
        domain: '${window.location.hostname}',
        path: '/dbconnections/signup',
      },
      {
        method: 'POST',
        domain: '${window.location.hostname}',
        path: '/dbconnections/change_password',
      }
    ]);
  })
  `;
  document.head.appendChild(scriptTagKasada);
  document.head.appendChild(scriptTagKasadaSrc);
};
