import React, { FC, useContext, useEffect } from 'react';
import { Button, rebrand, Auth0FormLayout } from '@flybuys/ui-kit';
import { handleChange, validateSignInUsername } from 'helpers/forms';
import {
  getPartnerClient,
  handleBlockedUser,
  handlePasswordlessLinkingError,
} from 'helpers/login';
import { useWebAuth } from 'providers/WebAuthProvider';
import { frontmatter } from 'metadata/contents';
import { AccountBlockedError } from 'helpers/errorUtils';
import {
  getPasswordlessServerError,
  getSignInFormProps,
  useUsernameForm,
} from 'helpers/signin';
import { SigninContext } from 'providers/SigninProvider';
import { BackButton } from '@flybuys/ui-kit';
import { events } from '../adobe/otp_events';

const { Form } = rebrand;
const { Header, ErrorMessage, FormLayout } = Auth0FormLayout;

export const SignInPage: FC = () => {
  const isUnlinking =
    new URLSearchParams(window.location.search).get('login_action') ===
    'delink';
  const {
    isBusy,
    authError,
    setAuthError,
    startPasswordless,
    otpError,
    setOtpError,
  } = useWebAuth();
  const { setScreen, setUsernameOrEmail, usernameOrEmail } =
    useContext(SigninContext);
  const form = { ...useUsernameForm() };
  const inputFieldRef = React.useRef();
  const partnerClient = getPartnerClient();
  const { form: metadata } = frontmatter;
  const { setUsernameError, setUsername } = form;
  const { formLayoutProps, formHeaderProps, formMessageProps } =
    getSignInFormProps(partnerClient, isUnlinking, metadata, authError);
  useEffect(() => {
    if (usernameOrEmail) {
      setUsername(usernameOrEmail);
    }
    handleBlockedUser(() => {
      setAuthError({ message: <AccountBlockedError /> });
    });
    handlePasswordlessLinkingError(error => {
      const err = getPasswordlessServerError(error);
      setUsernameError(err);
    });
  }, [setAuthError, setUsernameError, setUsername, usernameOrEmail]);

  const usernameNoProps = {
    id: metadata.username.id,
    name: metadata.username.name,
    label: 'Email',
    value: usernameOrEmail || form.username,
    errorMsg: form.usernameError || otpError,
    maxLength: 100,
    onChange: handleChange({
      setValue: form.setUsername,
    }),
    onBlur: () => {
      form.setUsernameError('');
      validateSignInUsername(form);
    },
    ariaDescribedBy: metadata.username.error.id,
    ref: inputFieldRef,
    withPadding: false,
  };
  const onKeyDown = (event: React.KeyboardEvent<HTMLFormElement>): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setOtpError('');
      form.setUsernameError('');
      if (validateSignInUsername(form)) {
        setUsernameOrEmail(form.username);
        startPasswordless(form.username, err => {
          if (err) return;
          events.signInWithOtpContinueBtn();
          setScreen('withOtp');
        });
      }
    }
  };

  return (
    <FormLayout
      {...formLayoutProps}
      topComponent={
        <div className="mx-10 my-4">
          <BackButton
            classNames="!text-fb-darkBlue underline"
            onClick={() => {
              setOtpError('');
              setScreen('default');
            }}
          >
            Back
          </BackButton>
        </div>
      }
    >
      {formMessageProps.message && <ErrorMessage {...formMessageProps} />}
      <Header {...formHeaderProps} />
      <div className="mt-24">
        <form
          onKeyDown={e => {
            onKeyDown(e);
          }}
          role="presentation"
        >
          <Form.TextField {...usernameNoProps} />
        </form>
        <Button
          loadingWhite={isBusy}
          color="primary"
          id="btn-login-continue"
          disabled={isBusy}
          ariaRole
          dataTestId="btn-login-continue"
          classNames="mt-12 mb-6 w-full"
          noArrow
          ariaLabel="Continue"
          onClick={() => {
            setOtpError('');
            if (validateSignInUsername(form)) {
              setUsernameOrEmail(form.username);
              startPasswordless(form.username, err => {
                if (err) return;
                events.signInWithOtpContinueBtn();
                setScreen('withOtp');
              });
            }
          }}
        >
          Continue
        </Button>
      </div>
    </FormLayout>
  );
};
