import React, { useEffect, useRef } from 'react';
import { camelCase } from 'lodash-es';
import {
  Button,
  rebrand,
  BackButton,
  Accordion,
  ButtonLink,
  Auth0FormLayout,
} from '@flybuys/ui-kit';
import { forms, validators, HostTypes } from '@flybuys/utils';
import { AnalyticsV2 } from '@flybuys/analytics';
import useAutoFocus from 'hooks/useAutoFocus';
import WhyDOBModal from 'components/modals/WhyDOBModal';
import {
  handleBlur,
  handleEmailBlur,
  handleChange,
  handleDOBChange,
  handleDOBBlur,
  continueSignUp,
  submitSignUp,
  handleCheckBoxChange,
  handleCardBlur,
  createPasswordGetErrors,
} from 'helpers/forms';

import { useWebAuth } from 'providers/WebAuthProvider';
import { frontmatter } from 'metadata/contents';
import { getHost } from 'helpers/urls';
import { getRegParams } from 'helpers/signup';
import FlybuysLogo from './FlybuysLogo';

const { Form, BodyText } = rebrand;
const { Header, ErrorMessage, FormLayout } = Auth0FormLayout;
const { joinForm: metadata } = frontmatter;

const {
  handlePasswordToggle,
  handlePasswordBlur,
  usePasswordForm,
  handlePasswordChange,
} = forms;

const useForm = (memNoExt: string, regNoExt: string) => {
  const [firstname, setFirstname] = React.useState('');
  const [firstnameError, setFirstnameError] = React.useState('');
  const [lastname, setLastname] = React.useState('');
  const [lastnameError, setLastnameError] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [emailError, setEmailError] = React.useState('');
  const [dob, setDob] = React.useState('');
  const [dobError, setDobError] = React.useState('');
  const [consent, setConsent] = React.useState(false);
  const [consentError, setConsentError] = React.useState('');
  const [memberNo, setMemberNo] = React.useState(memNoExt);
  const [memberNoError, setMemberNoError] = React.useState('');
  const [regoNo, setRegoNo] = React.useState(regNoExt);
  const [regoNoError, setRegoNoError] = React.useState('');
  const [tnc, setTnc] = React.useState(false);
  const [tncError, setTncError] = React.useState('');
  const [touched, setTouched] = React.useState(false);

  return {
    firstname,
    setFirstname,
    firstnameError,
    setFirstnameError,
    lastname,
    setLastname,
    lastnameError,
    setLastnameError,
    email,
    setEmail,
    emailError,
    setEmailError,
    dob,
    setDob,
    dobError,
    setDobError,
    consent,
    setConsent,
    consentError,
    setConsentError,
    touched,
    setTouched,
    memberNo,
    setMemberNo,
    memberNoError,
    setMemberNoError,
    regoNo,
    setRegoNo,
    regoNoError,
    setRegoNoError,
    tnc,
    setTnc,
    tncError,
    setTncError,
  };
};

export const SignUp = (): JSX.Element => {
  const { memNoExt, regNoExt } = getRegParams();
  const { signUp, isBusy, authError, setMode, isNextStep, setIsNextStep } =
    useWebAuth();
  const form = { ...usePasswordForm(), ...useForm(memNoExt, regNoExt) };
  const emailRef = useAutoFocus();
  const onKeyDown = (event: React.KeyboardEvent<HTMLFormElement>): void => {
    if (event.key === 'Enter' && isNextStep) {
      submitSignUp(form, signUp, metadata.cta.primary.join);
    }
    if (event.key === 'Enter' && !isNextStep) {
      continueSignUp(form, setIsNextStep, metadata);
    }
  };
  const divContainerRef = useRef(null);
  useEffect(() => {
    if (divContainerRef.current) {
      const divElem = divContainerRef.current as HTMLDivElement;
      divElem.setAttribute('tabindex', '0');
    }
  }, []);
  const emailProps = {
    id: metadata.email.id,
    name: metadata.email.name,
    label: metadata.email.label,
    value: form.email,
    errorMsg: form.emailError,
    maxLength: 100,
    onChange: handleChange({
      setValue: form.setEmail,
    }),
    onBlur: handleEmailBlur(validators.validateEmail, form.setEmailError),
    ariaDescribedBy: metadata.email.error.id,
    ref: emailRef,
    withPadding: true,
  };
  const passProps = {
    id: metadata.password.id,
    name: metadata.password.name,
    passwordToggle: form.passwordToggle,
    value: form.password,
    maxLength: 20,
    onChange: handlePasswordChange(form),
    onBlur: handlePasswordBlur(form),
    handlePasswordToggle: handlePasswordToggle(form),
    ariaDescribedBy: metadata.password.error.id,
    withPadding: false,
    errorMsg: createPasswordGetErrors(form),
    showErrorMessage: false,
  };

  const fnProps = {
    id: metadata.firstname.id,
    name: metadata.firstname.id,
    label: metadata.firstname.label,
    value: form.firstname,
    maxLength: 19,
    errorMsg: form.firstnameError,
    onChange: handleChange({
      setValue: form.setFirstname,
    }),
    onBlur: handleBlur(
      metadata.firstname.label,
      validators.validateFirstname,
      form.setFirstnameError
    ),
    ariaDescribedBy: metadata.firstname.error.id,
  };

  const lnProps = {
    name: metadata.lastname.name,
    label: metadata.lastname.label,
    value: form.lastname,
    maxLength: 20,
    errorMsg: form.lastnameError,
    onChange: handleChange({
      setValue: form.setLastname,
    }),
    onBlur: handleBlur(
      metadata.lastname.label,
      validators.validateName,
      form.setLastnameError
    ),
    ariaDescribedBy: metadata.lastname.error.id,
  };

  const dobProps = {
    value: form.dob,
    errorMsg: form.dobError,
    label: metadata.dob.label,
    onChange: handleDOBChange(form),
    onBlur: handleDOBBlur(form, validators.validateDOB),
    ariaDescribedBy: metadata.dob.error.id,
    withPadding: false,
  };

  const memberNoProps = {
    value: form.memberNo,
    label: metadata.tempCardMemberNo.label,
    onChange: handleChange({
      setValue: form.setMemberNo,
    }),
    onBlur: handleCardBlur(validators.validateMemberNum, form.setMemberNoError),
    errorMsg: form.memberNoError,
    ariaDescribedBy: metadata.tempCardMemberNo.error.id,
  };
  const regoNoProps = {
    value: form.regoNo,
    onChange: handleChange({
      setValue: form.setRegoNo,
    }),
    onBlur: handleCardBlur(validators.validateRegoNum, form.setRegoNoError),
    errorMsg: form.regoNoError,
    ariaDescribedBy: metadata.tempCardRegNo.error.id,
  };

  const tncProps = {
    value: form.tnc,
    label: (
      <span className="public-sans-rg font-light">
        I agree with&nbsp;
        <ButtonLink
          noArrow
          plain
          href={`${getHost(
            HostTypes.EXPERIENCE
          )}/flybuys-terms-and-conditions/`}
          target="_blank"
          classNames="inline-flex text-fb-darkBlue underline"
        >
          Terms & Conditions
        </ButtonLink>{' '}
        and&nbsp;
        <ButtonLink
          noArrow
          plain
          href={`${getHost(HostTypes.EXPERIENCE)}/policies/privacy-policy/`}
          target="_blank"
          classNames="inline-flex text-fb-darkBlue underline"
        >
          Privacy Policy
        </ButtonLink>
      </span>
    ),
    onChange: handleCheckBoxChange(form),
    errorMsg: form.tncError,
    ariaDescribedBy: metadata.tnc.error.id,
    classNames: {
      input: 'mt-8 font-public-sans-lt text-br-fb-link-lg font-light',
    },
    withPadding: false,
  };

  const tempCardAccordionProps = {
    isAccordionOpen: memNoExt && regNoExt,
    classNames: 'my-2',
    openLabel: 'I have a temporary card',
    closeLabel: 'I have a temporary card',
    wrapperClassNames: 'p-4 bg-fb-lightBlue bg-fb-lightBlue rounded-md mt-8',
  };

  const PIAccordionProps = {
    classNames: 'my-2',
    openLabel: 'How does Flybuys use your personal information?',
    closeLabel: 'How does Flybuys use your personal information?',
    wrapperClassNames: 'p-4 bg-fb-lightBlue bg-fb-lightGray rounded-md mt-8',
  };

  const heading = !isNextStep ? 'Welcome to Flybuys' : 'Account details';
  const description = !isNextStep
    ? 'Enter your details to set up your account.'
    : 'Your date of birth helps us verify your account when you contact us.';

  const topComponent = (
    <div className="w-full">
      <div className="flex h-[9px] w-full bg-fb-backgroundMidGray">
        <div className="h-full w-1/2 bg-fb-blue" />
        {isNextStep && <div className="h-full w-1/2 bg-fb-blue" />}
      </div>
      <BodyText className="w-full px-2 text-right text-fb-darkGray">
        Step {isNextStep ? 2 : 1} of 2
      </BodyText>
      {isNextStep && (
        <div className="mx-10 my-4">
          <BackButton
            classNames="!text-fb-darkBlue underline"
            onClick={() => setIsNextStep(false)}
          >
            Back
          </BackButton>
        </div>
      )}
    </div>
  );

  return (
    <FormLayout
      topComponent={topComponent}
      logo={<FlybuysLogo url={getHost(HostTypes.EXPERIENCE)} />}
    >
      {authError && (
        <ErrorMessage
          message={authError?.message}
          secondaryMessage={authError?.secondaryMessage}
        />
      )}
      <Header {...{ heading, description }} />
      <div className="mt-24">
        <form
          onKeyDown={e => {
            onKeyDown(e);
          }}
          role="presentation"
        >
          <div className={isNextStep ? 'hidden' : ''}>
            <Form.EmailField {...emailProps} />
            <Form.PasswordField {...passProps} />
            <div className="mb-12 pt-2 text-left">
              <Form.PasswordRules
                errors={form.passwordErrors}
                isPasswordFieldTouched={form.touched}
              />
            </div>
          </div>
          <div className={!isNextStep ? 'hidden' : ''}>
            <Form.TextField {...fnProps} />
            <Form.TextField {...lnProps} />
            <Form.DOBField {...dobProps} />
            <BodyText as="small" className="ml-4">
              <span className="mt-0.5 inline text-bu-fb-subtitle2">
                You must be over the age of 16 to create an account.
                <WhyDOBModal />
              </span>
            </BodyText>
            <Accordion {...tempCardAccordionProps}>
              <BodyText as="small" className="mt-8">
                Temporary cards are picked up in-store or by scanning a join now
                QR code at participating partners.
              </BodyText>
              <div className="mt-16">
                <Form.MemberNoField {...memberNoProps} />
                <Form.RegoNoField {...regoNoProps} />
              </div>
            </Accordion>
            <Form.CheckboxField {...tncProps} />
            <Accordion {...PIAccordionProps}>
              <BodyText as="small" className="mt-8">
                Flybuys collects your personal information for the purposes of
                giving you access to the Flybuys program, sending you offers and
                marketing communications, enabling personalised advertising
                across websites and social media and facilitating our corporate
                activities.
              </BodyText>
              <BodyText as="small" className="mt-8">
                If you do not provide us with your personal information, we will
                be unable to provide you with a Flybuys membership or our
                services. We may disclose your information to our Participants,
                service providers and other third parties engaged to assist us
                with conducting the Flybuys loyalty program. These third parties
                may be located overseas, including in the Philippines and the
                United States of America.
              </BodyText>
              <BodyText as="small" className="mt-8">
                Our Privacy Policy contains information about how you can seek
                to access or correct personal information we hold about you, how
                you can make a privacy complaint and how we will deal with a
                privacy complaint.You can contact us at privacy @flybuys.com.au.
              </BodyText>
            </Accordion>
          </div>
        </form>
      </div>
      <div className={`${isNextStep ? 'hidden' : ''} mt-24`}>
        <Button
          color="primary"
          id="btn-login"
          disabled={isBusy}
          classNames="mt-24 w-full"
          ariaLabel={metadata.cta.primary.continue}
          onClick={() => {
            continueSignUp(form, setIsNextStep, metadata.cta.primary.continue);
          }}
          noArrow
        >
          {metadata.cta.primary.continue}
        </Button>
        <div
          className="text-center"
          id="alreadyMemberSignInContainer"
          ref={divContainerRef}
        >
          <Button
            plain
            onClick={() => {
              AnalyticsV2.trackEvent('Registration_AlreadyMemberSignInLink', {
                buttonInfo: {
                  buttonName: metadata.cta.secondary.label,
                  buttonLink: '',
                },
              });
              setMode('signIn');
            }}
            classNames="block w-full mt-8 text-fb-darkBlue underline"
            ariaLabel={metadata.cta.secondary.label}
          >
            {metadata.cta.secondary.label}
          </Button>
        </div>
      </div>
      <div className={!isNextStep ? 'hidden' : ''}>
        <Button
          loadingWhite={isBusy}
          color="primary"
          disabled={isBusy}
          classNames="mt-12 w-full"
          ariaLabel={metadata.cta.primary.join}
          onClick={() => {
            submitSignUp(form, signUp, metadata.cta.primary.join);
          }}
          noArrow
          dataTestId={camelCase(metadata.cta.primary.join)}
        >
          {metadata.cta.primary.join}
        </Button>
      </div>
    </FormLayout>
  );
};
