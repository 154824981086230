"use client";

// src/base/table-header.tsx
import { TableHeader as TableHeaderBase } from "@react-stately/table";
var TableHeader = TableHeaderBase;
var table_header_default = TableHeader;

export {
  table_header_default
};
