import React from 'react';
import { rebrand, Auth0FormLayout } from '@flybuys/ui-kit';
import { AnalyticsV2 } from '@flybuys/analytics';
import { Error } from 'providers/WebAuthProvider';

const { Button } = rebrand;
const { Header, ErrorMessage, FormLayout } = Auth0FormLayout;

type PasswordRecoveryCheckMailProps = {
  email: string;
  resendMail: () => void;
  loading: boolean;
  authError: Error | undefined;
};
export const PasswordRecoveryCheckMail = ({
  email,
  resendMail,
  loading,
  authError,
}: PasswordRecoveryCheckMailProps): JSX.Element => {
  const formMessageProps = {
    message: authError?.message,
  };

  const formHeaderProps = {
    heading: 'Check your email',
    description: `Please check ${email} for instructions to reset your password.`,
    classNames: 'mt-4 mb-20',
  };
  return (
    <FormLayout>
      {authError?.message && (
        <p className="mt-5 text-center text-sm text-fb-rustyRed">
          {authError.message}
        </p>
      )}
      {authError?.message && <ErrorMessage {...formMessageProps} />}
      <img
        style={{
          marginTop: '15px',
        }}
        alt="Mail Logo"
        src="https://register.flybuys.com.au/cdn/edm/library/trigger/operational/email.svg"
      />
      <Header {...formHeaderProps} />

      <Button
        variant="primary"
        size="large"
        className="w-full"
        fluid
        loading={loading}
        onClick={() => {
          AnalyticsV2.trackEvent('Login_ResendResetPasswordEmail', {
            buttonInfo: {
              buttonName: 'Resend email',
              buttonLink: '',
            },
          });
          resendMail();
        }}
      >
        Resend email
      </Button>
    </FormLayout>
  );
};
