// List of flybuys partners that are able to link. Client Ids must be in line with CMS

export type Partner = {
  logo: string;
  name: string;
  auth0ClientId: string;
};

export const partners: Partner[] = [
  {
    logo: 'https://register.flybuys.com.au/cdn/web/img/CAT.svg',
    name: 'Catch',
    auth0ClientId: 'partner-catch-web',
  },
  {
    logo: 'https://register.flybuys.com.au/cdn/web/img/KLA.svg',
    name: 'Klarna',
    auth0ClientId: 'partner-klarna-app',
  },
  {
    logo: 'https://register.flybuys.com.au/cdn/web/img/COL.svg',
    name: 'Coles',
    auth0ClientId: 'partner-coles-app',
  },
  {
    logo: 'https://register.flybuys.com.au/cdn/web/img/ONP.svg',
    name: 'OnePass',
    auth0ClientId: 'partner-onepass-web',
  },
  {
    logo: 'https://register.flybuys.com.au/cdn/edm/library/partner/flybuys/offer/PAY.svg',
    name: 'Flypay',
    auth0ClientId: 'partner-flypay-web',
  },
];
