"use client";
import {
  scaleInOut
} from "./chunk-5NQHUNJL.mjs";
import {
  useModalContext
} from "./chunk-6JNB4JMH.mjs";

// src/modal-content.tsx
import { cloneElement, isValidElement, useMemo } from "react";
import { forwardRef } from "@nextui-org/system";
import { DismissButton } from "@react-aria/overlays";
import { TRANSITION_VARIANTS } from "@nextui-org/framer-transitions";
import { CloseIcon } from "@nextui-org/shared-icons";
import { RemoveScroll } from "react-remove-scroll";
import { motion } from "framer-motion";
import { useDialog } from "@react-aria/dialog";
import { mergeProps } from "@react-aria/utils";
import { jsx, jsxs } from "react/jsx-runtime";
var ModalContent = forwardRef((props, _) => {
  const { as, children, role = "dialog", ...otherProps } = props;
  const {
    Component: DialogComponent,
    domRef,
    slots,
    isOpen,
    classNames,
    motionProps,
    backdrop,
    closeButton,
    hideCloseButton,
    disableAnimation,
    shouldBlockScroll,
    getDialogProps,
    getBackdropProps,
    getCloseButtonProps,
    onClose
  } = useModalContext();
  const Component = as || DialogComponent || "div";
  const { dialogProps } = useDialog(
    {
      role
    },
    domRef
  );
  const closeButtonContent = isValidElement(closeButton) ? cloneElement(closeButton, getCloseButtonProps()) : /* @__PURE__ */ jsx("button", { ...getCloseButtonProps(), children: /* @__PURE__ */ jsx(CloseIcon, {}) });
  const content = /* @__PURE__ */ jsxs(Component, { ...getDialogProps(mergeProps(dialogProps, otherProps)), children: [
    /* @__PURE__ */ jsx(DismissButton, { onDismiss: onClose }),
    !hideCloseButton && closeButtonContent,
    typeof children === "function" ? children(onClose) : children,
    /* @__PURE__ */ jsx(DismissButton, { onDismiss: onClose })
  ] });
  const backdropContent = useMemo(() => {
    if (backdrop === "transparent") {
      return null;
    }
    if (disableAnimation) {
      return /* @__PURE__ */ jsx("div", { ...getBackdropProps() });
    }
    return /* @__PURE__ */ jsx(
      motion.div,
      {
        animate: "enter",
        exit: "exit",
        initial: "exit",
        variants: TRANSITION_VARIANTS.fade,
        ...getBackdropProps()
      }
    );
  }, [backdrop, disableAnimation, getBackdropProps]);
  return /* @__PURE__ */ jsxs("div", { tabIndex: -1, children: [
    backdropContent,
    /* @__PURE__ */ jsx(RemoveScroll, { forwardProps: true, enabled: shouldBlockScroll && isOpen, removeScrollBar: false, children: disableAnimation ? /* @__PURE__ */ jsx("div", { className: slots.wrapper({ class: classNames == null ? void 0 : classNames.wrapper }), children: content }) : /* @__PURE__ */ jsx(
      motion.div,
      {
        animate: "enter",
        className: slots.wrapper({ class: classNames == null ? void 0 : classNames.wrapper }),
        exit: "exit",
        initial: "exit",
        variants: scaleInOut,
        ...motionProps,
        children: content
      }
    ) })
  ] });
});
ModalContent.displayName = "NextUI.ModalContent";
var modal_content_default = ModalContent;

export {
  modal_content_default
};
