import React from 'react';
import ReactDOM from 'react-dom';
import { datadogRum } from '@datadog/browser-rum';
import { initKasada } from './helpers/kasada';
import './index.css';
import { App } from './App';
import { dummyAuth0Config, dummyPrConfig } from './metadata/dummyConfig';

if (process.env.APP_ENV?.startsWith('local')) {
  // use dummy Auth0 Config in local env
  window.guardianjs = null;
  window.auth0Config = dummyAuth0Config;
  window.prConfig = dummyPrConfig;
}
datadogRum.init({
  applicationId: process.env.RUM_APPLICATION_ID,
  clientToken: process.env.RUM_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: 'auth0-pages',
  version: process.env.RUM_APP_VERSION,
  env: process.env.APP_ENV,
  sampleRate: process.env.RUM_SAMPLE_RATE
    ? parseInt(process.env.RUM_SAMPLE_RATE, 10)
    : 100,
  premiumSampleRate: 100,
  trackUserInteractions: true,
  defaultPrivacyLevel: 'mask-user-input',
  allowedTracingUrls: [url => url.includes('/usernamepassword/login')],
});
datadogRum.startSessionReplayRecording();
initKasada();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('app')
);
