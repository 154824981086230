"use client";

// src/use-autocomplete.ts
import { mapPropsVariants } from "@nextui-org/system";
import { autocomplete } from "@nextui-org/theme";
import { useFilter } from "@react-aria/i18n";
import { useComboBox } from "@react-aria/combobox";
import { useComboBoxState } from "@react-stately/combobox";
import { useDOMRef } from "@nextui-org/react-utils";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { clsx, dataAttr } from "@nextui-org/shared-utils";
import { chain, mergeProps } from "@react-aria/utils";
function useAutocomplete(originalProps) {
  var _a, _b, _c;
  const [props, variantProps] = mapPropsVariants(originalProps, autocomplete.variantKeys);
  const disableAnimation = (_a = originalProps.disableAnimation) != null ? _a : false;
  const isClearable = originalProps.disableClearable !== void 0 ? !originalProps.disableClearable : originalProps.isClearable;
  const {
    ref,
    as,
    label,
    isLoading,
    menuTrigger = "focus",
    filterOptions = {
      sensitivity: "base"
    },
    children,
    selectorIcon,
    clearIcon,
    scrollRef: scrollRefProp,
    defaultFilter,
    endContent,
    allowsEmptyCollection = true,
    shouldCloseOnBlur = true,
    popoverProps = {},
    inputProps: userInputProps = {},
    scrollShadowProps = {},
    listboxProps = {},
    selectorButtonProps = {},
    clearButtonProps = {},
    showScrollIndicators = true,
    allowsCustomValue = false,
    className,
    classNames,
    onOpenChange,
    onClose,
    ...otherProps
  } = props;
  const { contains } = useFilter(filterOptions);
  const state = useComboBoxState({
    ...originalProps,
    children,
    menuTrigger,
    shouldCloseOnBlur,
    allowsEmptyCollection,
    defaultFilter: defaultFilter && typeof defaultFilter === "function" ? defaultFilter : contains,
    onOpenChange: (open, menuTrigger2) => {
      onOpenChange == null ? void 0 : onOpenChange(open, menuTrigger2);
      if (!open) {
        onClose == null ? void 0 : onClose();
      }
    }
  });
  const buttonRef = useRef(null);
  const inputWrapperRef = useRef(null);
  const listBoxRef = useRef(null);
  const popoverRef = useRef(null);
  const inputRef = useDOMRef(ref);
  const scrollShadowRef = useDOMRef(scrollRefProp);
  const slotsProps = {
    inputProps: mergeProps(
      {
        label,
        ref: inputRef,
        wrapperRef: inputWrapperRef,
        onClick: () => {
          if (!state.isOpen && !!state.selectedItem) {
            state.open();
          }
        },
        isClearable: false,
        disableAnimation
      },
      userInputProps
    ),
    popoverProps: mergeProps(
      {
        offset: 5,
        placement: "bottom",
        triggerScaleOnOpen: false,
        disableAnimation
      },
      popoverProps
    ),
    scrollShadowProps: mergeProps(
      {
        ref: scrollShadowRef,
        isEnabled: (_b = showScrollIndicators && state.collection.size > 5) != null ? _b : true,
        hideScrollBar: true,
        offset: 15
      },
      scrollShadowProps
    ),
    listboxProps: mergeProps(
      {
        hideEmptyContent: allowsCustomValue,
        emptyContent: "No results found.",
        disableAnimation
      },
      listboxProps
    ),
    selectorButtonProps: mergeProps(
      {
        isLoading,
        size: "sm",
        variant: "light",
        radius: "full",
        color: (originalProps == null ? void 0 : originalProps.isInvalid) ? "danger" : originalProps == null ? void 0 : originalProps.color,
        isIconOnly: true,
        disableAnimation
      },
      selectorButtonProps
    ),
    clearButtonProps: mergeProps(
      {
        size: "sm",
        variant: "light",
        radius: "full",
        color: (originalProps == null ? void 0 : originalProps.isInvalid) ? "danger" : originalProps == null ? void 0 : originalProps.color,
        isIconOnly: true,
        disableAnimation
      },
      clearButtonProps
    )
  };
  const baseStyles = clsx(classNames == null ? void 0 : classNames.base, className);
  const isOpen = ((_c = slotsProps.listboxProps) == null ? void 0 : _c.hideEmptyContent) ? state.isOpen && !!state.collection.size : state.isOpen;
  useEffect(() => {
    if (isOpen && popoverRef.current && inputWrapperRef.current) {
      let rect = inputWrapperRef.current.getBoundingClientRect();
      let popover = popoverRef.current;
      popover.style.width = rect.width + "px";
    }
  }, [isOpen]);
  useEffect(() => {
    if (!isOpen && !state.selectedItem && inputRef.current && !allowsCustomValue) {
      inputRef.current.blur();
    }
  }, [isOpen, allowsCustomValue]);
  const { buttonProps, inputProps, listBoxProps } = useComboBox(
    {
      ...originalProps,
      inputRef,
      buttonRef,
      listBoxRef,
      popoverRef
    },
    state
  );
  const Component = as || "div";
  const slots = useMemo(
    () => autocomplete({
      ...variantProps,
      isClearable,
      disableAnimation,
      className
    }),
    [...Object.values(variantProps), isClearable, disableAnimation, className]
  );
  const onClear = useCallback(() => {
    state.setInputValue("");
    state.setSelectedKey(null);
  }, [state]);
  const onFocus = useCallback(
    (isFocused) => {
      var _a2;
      (_a2 = inputRef.current) == null ? void 0 : _a2.focus();
      state.setFocused(isFocused);
    },
    [state, inputRef]
  );
  const getBaseProps = () => ({
    "data-invalid": dataAttr(originalProps == null ? void 0 : originalProps.isInvalid),
    "data-open": dataAttr(state.isOpen),
    className: slots.base({ class: baseStyles })
  });
  const getSelectorButtonProps = () => {
    var _a2;
    return {
      ref: buttonRef,
      ...mergeProps(buttonProps, slotsProps.selectorButtonProps),
      "data-open": dataAttr(state.isOpen),
      className: slots.selectorButton({
        class: clsx(classNames == null ? void 0 : classNames.selectorButton, (_a2 = slotsProps.selectorButtonProps) == null ? void 0 : _a2.className)
      })
    };
  };
  const getClearButtonProps = () => {
    var _a2, _b2;
    return {
      ...mergeProps(buttonProps, slotsProps.clearButtonProps),
      onPress: (e) => {
        var _a3, _b3;
        (_b3 = (_a3 = slotsProps.clearButtonProps) == null ? void 0 : _a3.onPress) == null ? void 0 : _b3.call(_a3, e);
        if (state.selectedItem) {
          onClear();
        } else {
          const inputFocused = inputRef.current === document.activeElement;
          allowsCustomValue && state.setInputValue("");
          !inputFocused && onFocus(true);
        }
      },
      "data-visible": !!state.selectedItem || ((_a2 = state.inputValue) == null ? void 0 : _a2.length) > 0,
      className: slots.clearButton({
        class: clsx(classNames == null ? void 0 : classNames.clearButton, (_b2 = slotsProps.clearButtonProps) == null ? void 0 : _b2.className)
      })
    };
  };
  const getInputProps = () => ({
    ...otherProps,
    ...inputProps,
    ...slotsProps.inputProps,
    onClick: chain(slotsProps.inputProps.onClick, otherProps.onClick)
  });
  const getListBoxProps = () => ({
    state,
    ref: listBoxRef,
    shouldHighlightOnFocus: true,
    ...mergeProps(slotsProps.listboxProps, listBoxProps)
  });
  const getPopoverProps = (props2 = {}) => {
    var _a2, _b2;
    return {
      state,
      ref: popoverRef,
      triggerRef: inputWrapperRef,
      scrollRef: listBoxRef,
      triggerType: "listbox",
      ...mergeProps(slotsProps.popoverProps, props2),
      classNames: {
        content: slots.popoverContent({
          class: clsx(
            classNames == null ? void 0 : classNames.popoverContent,
            (_b2 = (_a2 = slotsProps.popoverProps) == null ? void 0 : _a2.classNames) == null ? void 0 : _b2["content"],
            props2.className
          )
        })
      }
    };
  };
  const getListBoxWrapperProps = (props2 = {}) => {
    var _a2;
    return {
      ...mergeProps(slotsProps.scrollShadowProps, props2),
      className: slots.listboxWrapper({
        class: clsx(
          classNames == null ? void 0 : classNames.listboxWrapper,
          (_a2 = slotsProps.scrollShadowProps) == null ? void 0 : _a2.className,
          props2 == null ? void 0 : props2.className
        )
      })
    };
  };
  const getEndContentWrapperProps = (props2 = {}) => ({
    className: slots.endContentWrapper({
      class: clsx(classNames == null ? void 0 : classNames.endContentWrapper, props2 == null ? void 0 : props2.className)
    }),
    onClick: (e) => {
      const inputFocused = inputRef.current === document.activeElement;
      if (!inputFocused && !state.isFocused && e.currentTarget === e.target) {
        onFocus(true);
      }
    }
  });
  return {
    Component,
    inputRef,
    label,
    state,
    slots,
    classNames,
    isLoading,
    clearIcon,
    isOpen,
    endContent,
    isClearable,
    disableAnimation,
    allowsCustomValue,
    selectorIcon,
    getBaseProps,
    getInputProps,
    getListBoxProps,
    getPopoverProps,
    getClearButtonProps,
    getSelectorButtonProps,
    getListBoxWrapperProps,
    getEndContentWrapperProps
  };
}

export {
  useAutocomplete
};
