"use client";

// src/base/table-row.tsx
import { Row } from "@react-stately/table";
var TableRow = Row;
var table_row_default = TableRow;

export {
  table_row_default
};
