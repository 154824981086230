"use client";

// src/base/table-column.tsx
import { Column } from "@react-stately/table";
var TableColumn = Column;
var table_column_default = TableColumn;

export {
  table_column_default
};
