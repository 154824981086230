import React, { FC } from 'react';
import { Button, rebrand, Auth0FormLayout } from '@flybuys/ui-kit';
import {
  handleChange,
  handleOtpInputBlur,
  validateOtpInput,
} from 'helpers/forms';
import { getPartnerClient } from 'helpers/login';
import { useWebAuth } from 'providers/WebAuthProvider';
import { frontmatter } from 'metadata/contents';
import {
  getSignInFormProps,
  resendOtpMetadata,
  useOtpInputForm,
  validateOtpInputMetadata,
} from 'helpers/signin';
import { useSigninContext } from 'providers/SigninProvider';
import { BackButton } from '@flybuys/ui-kit';
import { events } from '../adobe/otp_events';
import Separator from './UiHelpers/Separator';

const { Form } = rebrand;
const { Header, FormLayout } = Auth0FormLayout;

export const SigninOtp: FC = () => {
  const isUnlinking =
    new URLSearchParams(window.location.search).get('login_action') ===
    'delink';
  const {
    isBusy,
    authError,
    passwordlessLogin,
    startPasswordless,
    otpError,
    setMode,
    setOtpError,
  } = useWebAuth();
  const { setScreen, usernameOrEmail } = useSigninContext();
  const form = { ...useOtpInputForm() };
  const inputFieldRef = React.useRef();
  const partnerClient = getPartnerClient();
  const { form: metadata } = frontmatter;
  const { formLayoutProps } = getSignInFormProps(
    partnerClient,
    isUnlinking,
    metadata,
    authError
  );
  const formHeaderProps = {
    heading: 'Enter your one-time code',
    description: `We've sent a code to ${usernameOrEmail}`,
  };
  const optInputProps = {
    id: validateOtpInputMetadata.id,
    name: validateOtpInputMetadata.name,
    label: validateOtpInputMetadata.label,
    value: form.otpInput,
    errorMsg: form.otpError || otpError,
    maxLength: 6,
    onChange: handleChange({
      setValue: form.setOtpInput,
    }),
    onBlur: handleOtpInputBlur({
      setOtpInput: form.setOtpInput,
      setOtpError: form.setOtpError,
    }),
    autoComplete: true,
    ref: inputFieldRef,
    withPadding: false,
  };
  const onKeyDown = (event: React.KeyboardEvent<HTMLFormElement>): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (validateOtpInput(form)) {
        passwordlessLogin(usernameOrEmail, form.otpInput);
      }
    }
  };
  return (
    <FormLayout
      {...formLayoutProps}
      topComponent={
        <div className="mx-10 my-4">
          <BackButton
            classNames="!text-fb-darkBlue underline"
            onClick={() => {
              setScreen('withPassword');
              setOtpError('');
            }}
          >
            Back
          </BackButton>
        </div>
      }
    >
      <Header {...formHeaderProps} />
      <div className="mt-24">
        <form
          onSubmit={e => {
            e.preventDefault();
            return false;
          }}
          onKeyDown={e => {
            onKeyDown(e);
          }}
          role="presentation"
        >
          <Form.TextField {...optInputProps} />
        </form>
        <Button
          plain
          onClick={() => {
            startPasswordless(usernameOrEmail);
          }}
          classNames="flex ml-4 mt-2 font-public-sans-lt text-fb-darkBlue underline"
          ariaLabel={resendOtpMetadata.btnText}
        >
          {resendOtpMetadata.btnText}
        </Button>
        <Button
          loadingWhite={isBusy}
          color="primary"
          id="btn-login"
          disabled={isBusy}
          ariaRole
          dataTestId="btn-login"
          classNames="mt-12 mb-4 w-full"
          noArrow
          ariaLabel="Sign in"
          onClick={() => {
            if (validateOtpInput(form)) {
              passwordlessLogin(usernameOrEmail, form.otpInput);
            }
          }}
        >
          Sign in
        </Button>
        <Separator />
        <Button
          color="primary"
          secondary
          id="btn-login-with-password"
          ariaRole
          dataTestId="btn-login-with-password"
          classNames="mt-4 mb-2 w-full"
          noArrow
          ariaLabel="Sign in with a password instead"
          onClick={() => {
            events.signInWithPasswordInstead();
            setOtpError('');
            setScreen('default');
            setMode('signIn');
          }}
        >
          Sign in with a password instead
        </Button>
      </div>
    </FormLayout>
  );
};
