import React from 'react';
import { Button } from '@flybuys/ui-kit';
import { useWebAuth } from 'providers/WebAuthProvider';
import { socialEvents } from '../adobe/social_events';

export const SocialSignIn = (): JSX.Element => {
  const { loginWithSocial } = useWebAuth();
  return (
    <>
      <Button
        id="btn-google"
        dataTestId="btn-google-login"
        classNames=" w-full my-4"
        secondary
        noArrow
        onClick={() => {
          socialEvents.signInWithGoogleBtnClicked();
          loginWithSocial('google-oauth2');
        }}
        ariaLabel="Continue with Google"
      >
        <span className="google h-5 w-5" />
        <span className="ml-4">Continue with Google</span>
      </Button>
      <Button
        id="btn-apple"
        dataTestId="btn-apple-login"
        classNames=" w-full my-4"
        secondary
        noArrow
        onClick={() => {
          socialEvents.signInWithAppleBtnClicked();
          loginWithSocial('apple');
        }}
        ariaLabel="Continue with Apple"
      >
        <span className="apple h-5 w-5" />
        <span className="ml-4">Continue with Apple</span>
      </Button>
    </>
  );
};
