import React from 'react';

interface FlybuyLogoProps {
  url?: string;
}

export default (props: FlybuyLogoProps) => {
  const { url } = props;
  return (
    <a href={url}>
      <img
        src='data:image/svg+xml;charset=utf-8,<svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="124" height="42" viewBox="0 0 130 44">
        <path
          fill="%23007cc2"
          fill-rule="evenodd"
          d="M52.922 0L47 1.298v19.23C47 26.31 51.475 31 56.996 31 62.52 31 67 26.31 67 20.528c0-5.783-4.48-10.472-10.004-10.472-1.442 0-2.81.322-4.05.898V0h-.024zM53 20c0-2.762 1.615-5 3.998-5C59.384 15 61 17.238 61 20s-1.616 5-4.002 5C54.615 25 53 22.762 53 20zM22.979 0L18 1.307V31h5V0h-.021zM10.74 0C3.31 0 .016 4.614 0 10.318V31h5.383V15.527h6.773V10.34H5.383v-.022h-.038c.019-2.852 1.804-5.17 5.395-5.17L15 5.144V0h-4.26zm101.495 16.096c.04 2.4 1.967 4.037 5.891 5.003l.313.086c3.91 1.072 5.534 1.517 5.534 2.899.013.405-.165.787-.508 1.112-.543.508-1.451.813-2.432.813-.303 0-2.965-.06-3.194-2.187l-.018-.195L112 24.873c.966 3.532 5.046 5.127 8.948 5.127 2.97 0 5.62-.877 7.277-2.409 1.192-1.106 1.81-2.494 1.773-4.02-.044-4.13-4.258-5.04-7.977-5.846-2.565-.585-3.721-1.236-3.754-2.105 0-.785.784-1.627 2.512-1.627 2.085 0 2.436 1.007 2.596 1.768l.035.205 5.713-1.223c-.959-3.47-4.804-4.743-8.3-4.743-4.976 0-8.588 2.56-8.588 6.096zm-8.653-4.765v9.849c0 2.708-1.205 4.218-3.582 4.218-2.378 0-3.582-1.51-3.582-4.218V10L91 11.328v9.852c0 5.868 3.617 9.946 9 9.946 1.306 0 2.51-.37 3.582-.806v3.613c0 3.412-1.832 4.34-3.426 4.34H98.83L98.826 44h1.328C105.61 44 109 40.144 109 33.933V10l-5.418 1.33zm-21.998-.071v9.324c0 2.564-1.207 3.993-3.583 3.993-2.377 0-3.582-1.429-3.582-3.993V10L69 11.257v9.327C69 26.14 72.618 30 78.001 30 83.385 30 87 26.14 87 20.584V10l-5.416 1.26zm-43.003.07v9.85c0 2.708-1.203 4.218-3.581 4.218-2.376 0-3.582-1.51-3.582-4.218V10L26 11.328v9.852c0 5.868 3.617 9.946 9 9.946 1.307 0 2.51-.37 3.583-.806v3.613c0 3.412-1.833 4.34-3.426 4.34h-1.326L33.827 44h1.328C40.61 44 44 40.144 44 33.933V12.575h-.002V10l-5.417 1.33z"
        ></path>
        </svg>'
        alt="Flybuys brand logo"
        style={{ width: '124.17px', height: '42px', margin: 0 }}
      />
    </a>
  );
};
