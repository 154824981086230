import React from 'react';
import { AnalyticsV2 } from '@flybuys/analytics';
import './VerificationCodeHelpModal.css';
import { Modal } from '@flybuys/ui-kit';

const VerificationCodeHelpModal = () => {
  const appElement = '#app';
  return (
    <Modal
      rootClassNames="verification-code-modal"
      appElement={appElement}
      openLabel="Didn't receive a code?"
      openerClassNames="open-code-help"
      contentLabel=""
      onOpen={() => {
        AnalyticsV2.trackEvent('MFA_NoVerificationCode', {
          buttonInfo: {
            buttonName: "Didn't receive a code?",
          },
        });
      }}
    >
      <div className="fb-modal-body-cms fb-no-code-modal-body public-sans-rg text-bu-fb-body-base font-light text-fb-darkGray">
        <div className="mb-5 font-public-sans-lt text-fb-h5 font-bold text-fb-black">
          Haven&apos;t received your verification code?
        </div>
        <p className="text-fb-midGray">Here are a few things to try:</p>
        <ol className="ml-4 list-disc text-fb-body-xs text-fb-midGray">
          <li>Check the phone number you inputted is correct</li>
          <li>Check your mobile has reception and is receiving SMS</li>
          <li>Try restarting your mobile device</li>
          <li>Try resending the code</li>
        </ol>
        <div className="mt-5 text-fb-body-xs text-fb-midGray">
          If this is still not working, contact flybuys support on{' '}
          <a href="tel:131116">13 11 16</a> to complete your registration
        </div>
      </div>
    </Modal>
  );
};
export default VerificationCodeHelpModal;
