"use client";

// src/base/table-cell.tsx
import { Cell } from "@react-stately/table";
var TableCell = Cell;
var table_cell_default = TableCell;

export {
  table_cell_default
};
