"use client";

// src/base/accordion-item-base.tsx
import { BaseItem } from "@nextui-org/aria-utils";
var AccordionItemBase = BaseItem;
var accordion_item_base_default = AccordionItemBase;

export {
  accordion_item_base_default
};
