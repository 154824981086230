import React, { FC, PropsWithChildren, useContext, useState } from 'react';
import { createContext } from 'react';

export interface SigninContext {
  screen: string;
  setScreen: React.Dispatch<React.SetStateAction<string>>;
  usernameOrEmail: string;
  setUsernameOrEmail: React.Dispatch<React.SetStateAction<string>>;
}

export const SigninContext = createContext<SigninContext>({
  screen: 'withPassword',
  setScreen: () => {},
  usernameOrEmail: '',
  setUsernameOrEmail: () => {},
});

export const SigninProvider: FC<PropsWithChildren> = ({ children }) => {
  const passwordlessLinkingError =
    /[&?]passwordless_linking_error=passwordless_error/iu.test(
      window.location.search
    );
  const [screen, setScreen] = useState(
    passwordlessLinkingError ? 'withEmail' : 'default'
  );
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  return (
    <SigninContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{ screen, setScreen, usernameOrEmail, setUsernameOrEmail }}
    >
      {children}
    </SigninContext.Provider>
  );
};

export const useSigninContext = () => {
  return { ...useContext(SigninContext) };
};
