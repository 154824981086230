import React from 'react';

const Separator = () => (
  <div className="relative my-4 flex items-center">
    <div className="flex-grow border-t border-black" />
    <span className="mx-4 flex-shrink">or</span>
    <div className="flex-grow border-t border-black" />
  </div>
);
export default Separator;
