"use client";

// src/base/tab-item-base.ts
import { BaseItem } from "@nextui-org/aria-utils";
var TabItemBase = BaseItem;
var tab_item_base_default = TabItemBase;

export {
  tab_item_base_default
};
